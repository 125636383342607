<template>
  <div>
    <v-dialog v-model="dialog">
      <div class="justify-space-between d-flex">
        <h1>資料請求顧客CSVアップロード</h1>
      </div>
      <div class="mt-3">
        <template>
          <v-file-input
                filled
                v-model="file"
                prepend-icon="fas fa-file-csv"
                v-if="file"
            ></v-file-input>
            <v-file-input
                v-else
                label="資料請求顧客CSVファイルをアップロード"
                filled
                v-model="file"
                prepend-icon="fas fa-file-csv"
            ></v-file-input>
        </template>
        <div class="justify-end d-flex">
          <v-btn large class="t-btn--red-dark mr-2" @click="closeDialog">
            <v-icon color="#FFFFFF"> mdi-close </v-icon>
            閉じる
          </v-btn>
          <v-btn large class="t-btn--prm" @click="save">アップロード</v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
export default {
  name: 'CSVUpload',
  data() {
    return {
      file: null,
    };
  },
  props: {
    visible: Boolean,
  },
  computed: {
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  mounted() {},
  methods: {
    ...mapActions(['UploadFileDuplicateExistingCustomers']),
    ...mapMutations(['setAlertSuccess', 'setAlertError']),
    async save() {
      if (this.file) {
        this.$store.commit('setLoadingOverlayShow');
        try {
          await new Promise((resolve, reject) => {
            const fileAsText = this.file;
            var reader = new FileReader();
            reader.readAsText(fileAsText);
            reader.onload = async () => {
              var file = new Blob([reader.result], { type: 'charset=utf-8;' });
              try {
                await this.UploadFileDuplicateExistingCustomers(file);
                resolve();
              } catch (e) {
                reject(e)
              }
            };

            reader.onerror = (e) => reject(e);
          })
        } finally {
          this.$store.commit('setLoadingOverlayHide');
          this.closeDialog();
        }
      }
    },
    closeDialog() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
// -- Dialog --
body {
  overflow: hidden;
}
h1 {
  color: #000000 !important;
  font-size: 22px !important;
  font-weight: bold !important;
}
.v-btn{
  height: 36px !important;
  color: #fff !important;
}
.t-btn--prm{
  background: #1873D9 !important;
  width: 110px !important;
}
.t-btn--red-dark{
  width: 85px !important;
}
::v-deep {
  .v-dialog {
    width: 500px;
    background-color: white;
    padding: 30px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }

  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
  .v-text-field__slot{
    .v-label{
      color: #606060 !important;
      font-size: 15px !important;
      font-weight: 500 !important;
    }
  }
}
.textCustomerSalesRepresentative {
  color: #006dab;
  font-weight: bolder;
}
</style>
