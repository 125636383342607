<template>
  <div>
    <v-simple-table
      fixed-header
      :dense="dense"
      :class="className"
      :v-bind="{ ...attr }"
    >
      <slot name="default"></slot>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: 'SimpleTable',
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.$store.commit('setLoadingOverlayShow', { root: true });
    setTimeout(() => {
      this.$store.commit('setLoadingOverlayHide', { root: true });
    }, 2000);
  },
  computed: {},
  props: {
    attr: Object,
    dense: {
      type: Boolean,
      default: false,
    },
    className: String,
  },
  methods: {},
};
</script>

<style scoped lang="scss"></style>
