<template>
  <page-manage-base>
    <template v-slot:title class="pl-5 booking-list-title"
      >{{ $route.name }}
    </template>
    <template v-slot:default>
      <template>
        <div class="d-flex justify-start mt-5 mb-2">
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="#13ACE0"></v-tabs-slider>
            <v-tab exact v-for="(item, i) in items" :key="i" :to="item.to"
              >{{ item.title }}
              <span
                v-if="
                  i === 0 && webRequestlistDocumentRequestlistFalse.length > 0
                "
                class="message"
                >{{ getTotal }}</span
              >
            </v-tab>
            <v-btn
              outlined
              download
              @click="showDialogUploadCV()"
              color="var(--text_primary)"
              class="ml-3 mt-2"
              >{{ $t('webPageManage.documentRequest.btnCsv') }}</v-btn
            >
          </v-tabs>
        </div>
      </template>
      <div class="mx-10">
        <slot name="content" />
      </div>
      <!-- Dialog -->
      <CSVUpload v-if="checkPerUser" :key="indexCSVUpload" :visible="visible === 3" @close="visible = -1" />
    </template>
  </page-manage-base>
</template>

<script>
import { mapGetters } from 'vuex';
import PageManageBase from '../PageManageBase.vue';
import CSVUpload from '../common/CSVUpload.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions'

export default {
  components: {
    PageManageBase,
    CSVUpload,
  },
  data() {
    return {
      indexCSVUpload : 0,
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      visible: -1,
      // Tabs
      tab: null,
      items: [
        {
          title: '営業担当なし',
          to: '/web-manage/document-request',
        },
        {
          title: '全て',
          to: '/web-manage/document-request/all',
        },
      ],
    };
  },
  mounted() {},
  computed: {
    ...mapGetters(['webRequestlistDocumentRequestlistFalse', 'getTotal']),
  },
  methods: {
    showDialogUploadCV(){
      this.indexCSVUpload = this.indexCSVUpload +1;
      this.visible = 3;
    }
  },
};
</script>

<style lang="scss" scoped>
.v-tab{
  color: #1B1B1B !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active){
  color: #4D4D4D !important;
}
.v-data-table::v-deep {
  th {
    color: #000 !important;
  }
  .v-data-footer,
  .v-select__selection {
    font-size: 14px !important;
  }
}
.message {
  margin-left: 5px;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  text-align: center;
  padding-top: 6px;
  background-color: #c92d2d;
  color: white;
}
.textCustomerSalesRepresentative {
  font-weight: bold;
  color: red;
}
</style>
