var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"justify-space-between d-flex"},[_c('h1',[_vm._v("割振結果")])]),_c('div',{staticClass:"mt-3"},[_c('v-data-table',{staticClass:"custom-table",attrs:{"footer-props":{
          itemsPerPageText:
            _vm.numLength +
            _vm.$t('commons.cases') +
            _vm.itemStart +
            '~' +
            _vm.itemStop +
            _vm.$t('commons.showing'),
          itemsPerPageOptions: [10, 20, 50, 100, 200, 500],
        },"headers":_vm.headers,"item-key":"id","no-data-text":"データなし","items":_vm.suggestClientPIC},on:{"pagination":_vm.getNumOfPage},scopedSlots:_vm._u([{key:"item.MediaType",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.webRequest.medias.map(function (c) { return c.mediaType; }).join(', ')))])]}},{key:"item.SuggestedPic",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"edit-suggested-pic-button"},'span',attrs,false),on),[_vm._v(_vm._s(item.suggestedPic.name))])]}}],null,true)},[_c('v-list',[_c('div',{staticClass:"px-2 div-select-dialog"},[_c('v-select',{staticClass:"my-1",attrs:{"disabled":!_vm.checkPerUser,"dense":"","items":_vm.itemsSuggestPic,"item-value":"picStaffName","item-text":"picStaffName"},on:{"change":function($event){return _vm.updateItemsEditSelected(item)}},model:{value:(item.suggestedPic.name),callback:function ($$v) {_vm.$set(item.suggestedPic, "name", $$v)},expression:"item.suggestedPic.name"}})],1)])],1)]}},{key:"item.CreatedAt",fn:function(ref){
        var item = ref.item;
return [(item.webRequest.createdAt)?_c('span',[_vm._v(_vm._s(_vm.formatDate(item.webRequest.createdAt)))]):_vm._e()]}},{key:"item.IsCompany",fn:function(ref){
        var item = ref.item;
return [(item.webRequest.isCompany === true)?_c('span',[_vm._v("法人")]):(item.webRequest.isCompany === false)?_c('span',[_vm._v("個人")]):_c('span')]}},{key:"item.ForVMForVS",fn:function(ref){
        var item = ref.item;
return [(item.webRequest.forVm === true)?_c('span',[_vm._v("VM")]):_vm._e(),(item.webRequest.forVs === true)?_c('span',[(item.webRequest.forVm === true)?_c('span',[_vm._v(",")]):_vm._e(),_vm._v("VS")]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"justify-end d-flex mt-3"},[_c('v-btn',{staticClass:"t-btn--red-dark mr-2",attrs:{"large":""},on:{"click":_vm.closeDialog}},[_c('v-icon',{attrs:{"color":"#FFFFFF"}},[_vm._v(" mdi-close ")]),_vm._v(" 閉じる ")],1),_c('v-btn',{staticClass:"t-btn--prm",attrs:{"large":"","disabled":!_vm.checkPerUser},on:{"click":_vm.save}},[_vm._v("営業担当決定")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }