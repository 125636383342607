<template>
  <div>
    <v-dialog v-model="dialog">
      <!-- Title and button -->
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <h1>
            資料請求_
            {{ nameClient }}
            <!-- {{ webrequestNotIscopanyDocumentRequestInfo.client && webrequestNotIscopanyDocumentRequestInfo.client.name }} -->
            ({{
              webrequestNotIscopanyDocumentRequestInfo.isCompany
                ? '法人'
                : '個人'
            }})
          </h1>
          <v-btn
            class="mt-1 ml-4 btn-crm-primary"
            target="_blank"
            :to="
              '/customer-details/' +
                idClient
            "
            >{{
              $t('webPageManage.documentRequest.OpenCustomerInformation')
            }}</v-btn
          >
        </div>
        <div class="d-flex">
          <v-btn
            class="mt-1 mr-3 t-btn--red-dark"
            style="color: white"
            @click="closeDialog"
          >
            <v-icon class="mr-2">mdi-close</v-icon>
            {{ $t('webPageManage.documentRequest.close') }}
          </v-btn>
          <v-btn class="t-btn--prm-dark mt-1" @click="handleUpdate">保存</v-btn>
        </div>
      </div>
      <!-- And Title and button -->

      <medias :medias="webrequestNotIscopanyDocumentRequestInfo.medias" />

      <!-- sales representative -->
      <div class="mt-5" v-if="!hidePic">
        <h2 class="textTile">
          {{ $t('webPageManage.documentRequest.CustomersSales') }}
        </h2>
        <v-select
          class="mt-1"
          :items="itemsData.picList"
          v-model="dataPicIDSelected"
          style="width: 300px"
          item-text="name"
          item-value="id"
          label="営業担当者"
        >
        </v-select>
      </div>
      <!-- end sales representative -->

      <!-- Document inquiry information -->
      <div class="mt-5">
        <h2>
          {{ $t('webPageManage.documentRequest.DocumentInquiryInformation') }}
        </h2>
        <v-row class="mt-1">
          <v-col :cols="3">
            <v-card class="h-100">
              <div
                class="d-flex justify-space-between pos-relative flex-column"
                style="padding: 20px"
              >
                <div class="text-divide w-100">
                  <div class="d-flex justify-space-between">
                    <p>資料請求内容</p>
                    <p>
                      <span>
                        {{
                          showForVmVs(webrequestNotIscopanyDocumentRequestInfo)
                        }}
                      </span>
                    </p>
                  </div>
                  <div class="d-flex justify-space-between">
                    <p>資料請求日</p>
                    <p>
                      {{
                        webrequestNotIscopanyDocumentRequestInfo.createdAt
                          ? formatDate(
                              webrequestNotIscopanyDocumentRequestInfo.createdAt,
                            )
                          : '-'
                      }}
                    </p>
                  </div>
                  <div class="d-flex justify-space-between">
                    <p>姓</p>
                    <p>
                      {{
                        webrequestNotIscopanyDocumentRequestInfo.lastName
                          ? webrequestNotIscopanyDocumentRequestInfo.lastName
                          : '-'
                      }}
                    </p>
                  </div>
                  <div class="d-flex justify-space-between">
                    <p>名</p>
                    <p>
                      {{
                        webrequestNotIscopanyDocumentRequestInfo.firstName
                          ? webrequestNotIscopanyDocumentRequestInfo.firstName
                          : '-'
                      }}
                    </p>
                  </div>
                  <div class="d-flex justify-space-between">
                    <p>姓カナ</p>
                    <p>
                      {{
                        webrequestNotIscopanyDocumentRequestInfo.lastKana
                          ? webrequestNotIscopanyDocumentRequestInfo.lastKana
                          : '-'
                      }}
                    </p>
                  </div>
                  <div class="d-flex justify-space-between">
                    <p>名カナ</p>
                    <p>
                      {{
                        webrequestNotIscopanyDocumentRequestInfo.firstKana
                          ? webrequestNotIscopanyDocumentRequestInfo.firstKana
                          : '-'
                      }}
                    </p>
                  </div>
                  <div class="d-flex justify-space-between">
                    <p>生年月日</p>
                    <p>
                      {{
                        webrequestNotIscopanyDocumentRequestInfo.birthday
                          ? formatDate(
                              webrequestNotIscopanyDocumentRequestInfo.birthday,
                            )
                          : '-'
                      }}
                    </p>
                  </div>
                  <div class="d-flex justify-space-between">
                    <p>性別</p>
                    <p>
                      <span v-if="webrequestNotIscopanyDocumentRequestInfo.sex">
                        <gender :value="webrequestNotIscopanyDocumentRequestInfo.sex" />
                      </span>
                      <span v-else>-</span>
                    </p>
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col :cols="5"
            ><v-card class="h-100">
              <div
                class="d-flex justify-space-between pos-relative flex-column"
                style="padding: 20px"
              >
                <div class="text-divide w-100">
                  <div class="d-flex justify-space-between">
                    <p>自宅郵便番号</p>
                    <p>
                      {{
                        webrequestNotIscopanyDocumentRequestInfo.postalCode
                          ? webrequestNotIscopanyDocumentRequestInfo.postalCode
                          : '-'
                      }}
                    </p>
                  </div>
                  <div class="d-flex justify-space-between">
                    <p>自宅都道府県</p>
                    <p>
                      {{
                        webrequestNotIscopanyDocumentRequestInfo.prefecture
                          ? webrequestNotIscopanyDocumentRequestInfo.prefecture
                          : '-'
                      }}
                    </p>
                  </div>
                  <div class="d-flex justify-space-between">
                    <p>自宅市区郡</p>
                    <p>
                      {{
                        webrequestNotIscopanyDocumentRequestInfo.locality
                          ? webrequestNotIscopanyDocumentRequestInfo.locality
                          : '-'
                      }}
                    </p>
                  </div>
                  <div class="d-flex justify-space-between">
                    <p>自宅町名・番地）</p>
                    <p>
                      {{
                        webrequestNotIscopanyDocumentRequestInfo.address1
                          ? webrequestNotIscopanyDocumentRequestInfo.address1
                          : '-'
                      }}
                    </p>
                  </div>
                  <div class="d-flex justify-space-between">
                    <p>自宅ビル名・マンション名VS</p>
                    <p>
                      {{
                        webrequestNotIscopanyDocumentRequestInfo.address2
                          ? webrequestNotIscopanyDocumentRequestInfo.address2
                          : '-'
                      }}
                    </p>
                  </div>
                  <div class="d-flex justify-space-between">
                    <p>自宅電話番号</p>
                    <p>
                      {{
                        webrequestNotIscopanyDocumentRequestInfo.tel
                          ? webrequestNotIscopanyDocumentRequestInfo.tel
                          : '-'
                      }}
                    </p>
                  </div>
                  <div class="d-flex justify-space-between">
                    <p>自宅携帯電話番号</p>
                    <p>
                      {{
                        webrequestNotIscopanyDocumentRequestInfo.telMobile
                          ? webrequestNotIscopanyDocumentRequestInfo.telMobile
                          : '-'
                      }}
                    </p>
                  </div>
                  <div class="d-flex justify-space-between">
                    <p>自宅メールアドレス</p>
                    <p>
                      {{
                        webrequestNotIscopanyDocumentRequestInfo.email
                          ? webrequestNotIscopanyDocumentRequestInfo.email
                          : '-'
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col :cols="4">
            <v-card class="h-100">
              <div style="padding: 20px">
                <div class="w-100">
                  <div class="d-flex justify-space-between text--divide">
                    <div>
                      <p>興味のある施設</p>
                    </div>
                    <div class="w-70">
                      <div
                        v-if="
                          webrequestNotIscopanyDocumentRequestInfo.facilities
                        "
                      >
                        <p
                          v-for="(item,
                          idx) in webrequestNotIscopanyDocumentRequestInfo.facilities"
                          :key="idx"
                        >
                          {{ item }}
                        </p>
                      </div>
                      <div v-else><p>-</p></div>
                    </div>
                  </div>
                  <div class="d-flex justify-space-between text--divide">
                    <div>
                      <p>興味のあるシーズン</p>
                    </div>
                    <div class="w-70">
                      <div
                        v-if="webrequestNotIscopanyDocumentRequestInfo.seasons"
                      >
                        <p
                          v-for="(item,
                          idx) in webrequestNotIscopanyDocumentRequestInfo.seasons"
                          :key="idx"
                        >
                          {{ item }}
                        </p>
                      </div>
                      <div v-else><p>-</p></div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <!-- End Document inquiry information -->

      <!-- Adaptive advertising measures -->
      <div class="mt-5">
        <h2>
          {{
            $t('webPageManage.documentRequest.AdaptiveAdvertisingMeasures')
          }}({{ $t('webPageManage.documentRequest.multiple') }})
        </h2>
        <v-card class="mt-2">
          <SimpleTable>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    広告施策名
                  </th>
                  <th class="text-left">
                    広告施策グループ名
                  </th>
                  <th class="text-left">
                    追加日
                  </th>
                  <th class="text-left">
                    説明
                  </th>
                  <th class="text-left">
                    発送資料
                  </th>
                  <th class="text-left">
                    発送資料
                  </th>
                  <th class="text-left">
                    発送資料
                  </th>
                  <!-- <th class="text-left">
                    削除
                  </th> -->
                  <th class="text-left">
                    施策詳細
                  </th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-if="
                    webrequestNotIscopanyDocumentRequestInfo.appliedCampaigns
                      .length > 0
                  "
                >
                  <tr
                    v-for="(item,
                    idx) in webrequestNotIscopanyDocumentRequestInfo.appliedCampaigns"
                    :key="idx"
                  >
                    <td>{{ item.name }}</td>
                    <td>{{ item.groups && item.groups.join(', ') }}</td>
                    <td>
                      {{
                        item.startDate &&
                          moment(item.startDate).format('YYYY/MM/DD')
                      }}
                    </td>
                    <td>{{ item.note }}</td>
                    <td>
                      <v-btn
                        v-if="item.attachment1Url"
                        @click="openDownloadAttachment(item.attachment1Url)"
                        class="t-btn--prm"
                        icon
                      >
                        <v-icon>mdi-file-download</v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn
                        v-if="item.attachment2Url"
                        @click="openDownloadAttachment(item.attachment2Url)"
                        class="t-btn--prm"
                        icon
                      >
                        <v-icon>mdi-file-download</v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn
                        v-if="item.attachment3Url"
                        @click="openDownloadAttachment(item.attachment3Url)"
                        class="t-btn--prm"
                        icon
                      >
                        <v-icon>mdi-file-download</v-icon>
                      </v-btn>
                    </td>
                    <!-- <td>
                      <v-btn class="t-btn--red-dark" icon>
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td> -->
                    <td>
                      <v-btn
                        class="t-btn--prm"
                        icon
                        @click="
                          (openDetailDialog = true),
                            (idPolicy = parseInt(item.id))
                        "
                      >
                        <v-icon>fas fa-ad</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td
                      style="color: #757575 !important; font-weight: 400"
                      class="text-center text-heading"
                      colspan="12"
                    >
                      {{ $t('rules.noData') }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </SimpleTable>
        </v-card>
      </div>
      <!-- End Adaptive advertising measures -->

      <!-- Activity history registration -->
      <div class="mt-5">
        <h2>
          {{ $t('webPageManage.documentRequest.ActivityHistoryRegistration') }}
        </h2>
        <h4 class="my-3">
          状況 :
          <span>{{
            webrequestNotIscopanyDocumentRequestInfo.actionRemarks
              ? webrequestNotIscopanyDocumentRequestInfo.actionRemarks
              : ''
          }}</span>
        </h4>
        <h4 class="my-3">
          購入評価 :
          <span v-if="webrequestNotIscopanyDocumentRequestInfo.purchaseRate">
            {{
              webrequestNotIscopanyDocumentRequestInfo.purchaseRate.name
                ? webrequestNotIscopanyDocumentRequestInfo.purchaseRate.name
                : ''
            }}
          </span>
        </h4>
      </div>
      <!-- End Activity history registration -->

      <!-- Remarks -->
      <div class="mt-5">
        <h2 class="textTile">備考</h2>
        <h6 class="my-3">備考</h6>
        <v-textarea
          outlined
          v-model="dataClientRemarksSelected"
          no-resize
          :rules="[$rules.checkTextAreaLength()]"
          class="textarea-remarks-custom mt-2"
        >
        </v-textarea>
      </div>
      <!-- End Remarks -->
    </v-dialog>
    <AdvertisingPoliciesDetailDialog
      v-if="openDetailDialog"
      @close="openDetailDialog = false"
      :idPolicy="idPolicy"
      @fetchList="getCampaignList"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { UPDATE_WEB_REQUEST_REMARKS_AND_PICID } from '@/api/graphql/dashboard/webPageManage/documentrequest/DuplicateExistingCustomers';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions';
import AdvertisingPoliciesDetailDialog from '@/views/advertising/policies/advertisingPoliciesDetailDialog.vue';
import moment from 'moment';
import gql from 'graphql-tag';
import Medias from '../../common/Medias.vue';
import SimpleTable from '@/components/Table/simpleTable.vue';
import Gender from '@/components/shared/Gender.vue';
// import { CheckLenghInput } from '@/utils/validate';

export default {
  name: 'documentRequestDetailIndividual',
  components: {
    AdvertisingPoliciesDetailDialog,
    Medias,
    SimpleTable,
    Gender,
  },
  data() {
    return {
      openDetailDialog: false,
      idPolicy: null,
      filter: {},
      pagination: {},
      itemsData: {
        picList: [],
      },
      // rules: {
      //   remarks: [
      //     (v) =>
      //       (v && (CheckLenghInput(v, 255))) ||
      //       !v ||
      //       this.$t('rules.maximumNCharacter', { value: 255 }),
      //   ],
      // },
    };
  },
  props: {
    visible: Boolean,
    dataUrl: Object,
    hidePic: Boolean
  },
  computed: {
    ...mapGetters([
      'webrequestNotIscopanyDocumentRequestInfo',
      'getClientRemarks',
      'getClientPicID',
    ]),
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    dataPicIDSelected: {
      get() {
        return this.getClientPicID;
      },
      set(value) {
        this.setClientPicID(value);
      },
    },
    dataClientRemarksSelected: {
      get() {
        return this.getClientRemarks;
      },
      set(value) {
        this.setClientRemarks(value);
      },
    },
    nameClient () {
      return this.webrequestNotIscopanyDocumentRequestInfo.client?.name ?? this.webrequestNotIscopanyDocumentRequestInfo.name
    },
    idClient () {
      return this.webrequestNotIscopanyDocumentRequestInfo.clientId
    }
  },
  mounted() {
    this.actionStaffList().then(pic => {
      this.itemsData.picList.push({ id: null, name: '指定なし' });
      pic.forEach(element => {
        this.itemsData.picList.push({ id: element.id, name: element.name });
      });
    });
  },
  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapActions([
      'getWebrequestNotIscopanyDocumentRequestList',
      'getWebRequestlistDocumentRequestlist',
      'actionStaffList',
    ]),
    ...mapMutations([
      'setCopyErrorText',
      'setClientRemarks',
      'setClientPicID',
      'setAlertSuccess',
      'setAlertError',
      'setLoadingOverlayShow',
      'setLoadingOverlayHide',
      'setPermissionUser',
      'setRoleAdminUser',
    ]),
    showForVmVs(webrequestNotIscopanyDocumentRequestInfo) {
      var arr = [];
      if (webrequestNotIscopanyDocumentRequestInfo.forVm) {
        arr.push('VM');
      }
      if (webrequestNotIscopanyDocumentRequestInfo.forVs) {
        arr.push('VS');
      }
      return arr.toString();
    },
    openDownloadAttachment(url) {
      window.open(url);
    },
    formatDate(date) {
      return (
        date.substr(0, 4) + '/' + date.substr(5, 2) + '/' + date.substr(8, 2)
      );
    },
    closeDialog() {
      this.$emit('close');
    },
    async handleUpdate() {
      const updateData = {
        id: this.dataUrl.id,
        remarks: this.getClientRemarks,
        picId: this.getClientPicID,
      };
      this.setLoadingOverlayShow();
      await this.$apollo
        .mutate({
          mutation: gql`
            ${UPDATE_WEB_REQUEST_REMARKS_AND_PICID}
          `,
          variables: updateData,
        })
        .then(async data => {
          await this.$emit(
            'reloadList',
            data.data.updateWebRequest,
            this.dataUrl.id,
          );
          this.setAlertSuccess('更新しました。');
          this.setLoadingOverlayHide();
        })
        .catch(async error => {
          this.setLoadingOverlayHide();
          this.setCopyErrorText(
            this.getCopyErrorTextView(
              UPDATE_WEB_REQUEST_REMARKS_AND_PICID,
              updateData,
              error.graphQLErrors,
            ),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          } else {
            this.setAlertError('更新失敗');
          }
        });
      this.closeDialog();
    },

    async getCampaignList() {
      this.getWebRequestlistDocumentRequestlist();
    },

    moment,
  },
};
</script>

<style lang="scss" scoped>
// Dialog
body {
  overflow: hidden;
}
h1 {
  font-size: 22px;
  font-weight: bold;
  color: #000000 !important;
}
h2 {
  font-size: 20px !important;
  font-weight: bold;
  color: #0b6786 !important;
}
h4 {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #1b1b1b !important;
}
h6 {
  font-size: 10px !important;
  color: #000000 !important;
  font-weight: 600 !important;
}
.t-btn--prm-dark {
  background: #1873d9 !important;
}

.text-divide {
  div {
    p:nth-child(1) {
      color: #757575 !important;
      font-size: 16px !important;
      font-weight: bold !important;
    }
    p:nth-child(2) {
      color: #424242 !important;
      font-weight: bold !important;
      font-size: 17px !important;
    }
  }
}
.text--divide {
  div:nth-child(1) {
    p {
      color: #757575 !important;
      font-size: 16px !important;
      font-weight: bold !important;
      white-space: nowrap;
    }
  }

  div:nth-child(2) {
    p {
      text-align: end;
      color: #424242 !important;
      font-weight: bold !important;
      font-size: 17px !important;
      white-space: normal;
    }
  }
}
.w-100 {
  width: 100%;
}
.w-70 {
  width: 70%;
}
.h-100 {
  height: 100%;
}
.pos-relative {
  position: relative;
}
::v-deep {
  .blod {
    font-weight: bold;
  }
  .v-dialog {
    max-width: 1600px;
    background-color: white;
    padding: 30px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 10px !important;
      font-weight: 600;
      color: #000000 !important;
    }
    .v-select__selection--comma {
      font-size: 14px !important;
      font-weight: 500;
      color: #000000 !important;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
  .textTile {
    color: #0b6786;
  }
  .btn-crm-primary .v-btn__content {
    font-size: 12px !important;
    color: #1873d9;
  }
  .btn-crm-primary {
    border: 0.5px solid #1873d9;
    height: 28px !important;
    min-width: 88px !important;
    padding: 0 13px;
  }
  .v-icon__svg {
    color: #333 !important;
  }
  .v-data-table-header {
    tr {
      th {
        font-size: 14px !important;
        color: #757575 !important;
        font-weight: bold !important;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: 16px !important;
        color: #757575 !important;
        font-weight: 400 !important;
      }
    }
  }
  .v-data-footer__select {
    font-size: 13px !important;
    color: #000000;
    font-weight: 500;
  }
}
// End Dialog
.textarea-remarks-custom {
  width: 45%;
  ::v-deep {
    .v-input__slot {
      fieldset {
        border: 1px solid #707070;
        border-radius: 7px;
      }
    }
    .v-text-field__slot {
      textarea {
        color: #000000;
      }
    }
  }
  // border: 1px solid black;
  // min-height: 200px;
  // border-radius: 10px;
  // padding: 10px 20px;
}
.btn-link {
  text-decoration: none;
  color: inherit;
}
</style>
