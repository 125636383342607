<template>
  <div class="mt-5">
  <h2>
    {{ $t('webPageManage.documentRequest.medium') }}
  </h2>
  <h4 class="mt-1 blod" style="min-height: 20px">
    <span
      v-for="(item, idx) in (medias ? medias : [])"
      :key="idx"
    >
     {{ idx ? ', ' : '' }}{{item.mediaType}}<span v-if="item.mediaOther">({{item.mediaOther}})</span>
    </span>
  </h4>
</div>
</template>

<script>
export default {
  props: {
    medias: Array,
  }
}
</script>
