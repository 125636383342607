<template>
  <div>
    <v-dialog v-model="dialog">
      <div class="justify-space-between d-flex">
        <h1>割振結果</h1>
      </div>
      <div class="mt-3">
        <v-data-table
          :footer-props="{
            itemsPerPageText:
              numLength +
              $t('commons.cases') +
              itemStart +
              '~' +
              itemStop +
              $t('commons.showing'),
            itemsPerPageOptions: [10, 20, 50, 100, 200, 500],
          }"
          :headers="headers"
          item-key="id"
          class="custom-table"
          @pagination="getNumOfPage"
          no-data-text="データなし"
          :items="suggestClientPIC"
        >
          <template v-slot:[`item.MediaType`]="{ item }">
            <span>{{
              item.webRequest.medias.map((c) => c.mediaType).join(', ')
            }}</span>
          </template>
          <template v-slot:[`item.SuggestedPic`]="{ item }">
            <v-menu bottom offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <span
                  class="edit-suggested-pic-button"
                  v-bind="attrs"
                  v-on="on"
                  >{{ item.suggestedPic.name }}</span
                >
              </template>
              <v-list>
                <div class="px-2 div-select-dialog">
                  <v-select
                    :disabled="!checkPerUser"
                    dense
                    class="my-1"
                    v-model="item.suggestedPic.name"
                    :items="itemsSuggestPic"
                    item-value="picStaffName"
                    item-text="picStaffName"
                    @change="updateItemsEditSelected(item)"
                  >
                  </v-select>
                </div>
              </v-list>
            </v-menu>
          </template>

          <template v-slot:[`item.CreatedAt`]="{ item }">
            <span v-if="item.webRequest.createdAt">{{
              formatDate(item.webRequest.createdAt)
            }}</span>
          </template>
          <template v-slot:[`item.IsCompany`]="{ item }">
            <span v-if="item.webRequest.isCompany === true">法人</span>
            <span v-else-if="item.webRequest.isCompany === false">個人</span>
            <span v-else></span>
          </template>
          <template v-slot:[`item.ForVMForVS`]="{ item }">
            <span v-if="item.webRequest.forVm === true">VM</span>
            <span v-if="item.webRequest.forVs === true"
              ><span v-if="item.webRequest.forVm === true">,</span>VS</span
            >
          </template>
        </v-data-table>
        <div class="justify-end d-flex mt-3">
          <v-btn large class="t-btn--red-dark mr-2" @click="closeDialog">
            <v-icon color="#FFFFFF"> mdi-close </v-icon>
            閉じる
          </v-btn>
          <v-btn
            large
            class="t-btn--prm"
            @click="save"
            :disabled="!checkPerUser"
            >営業担当決定</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import { checkPermissionUserCurrent } from '@/utils/permissions';

export default {
  name: 'AllocationResult',
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      headers: [
        {
          text: '顧客営業担当',
          sortable: false,
          value: 'SuggestedPic',
          width: '100',
        },
        {
          text: '反響媒体',
          sortable: true,
          value: 'MediaType',
          width: '300',
        },

        {
          text: this.$t('webPageManage.documentRequest.date'),
          value: 'CreatedAt',
          width: '100',
        },
        {
          text: this.$t('webPageManage.documentRequest.customer'),
          sortable: false,
          value: 'IsCompany',
          width: '100',
        },
        {
          text: this.$t('webPageManage.documentRequest.companyName'),
          sortable: false,
          value: 'webRequest.name',
          width: '100',
        },

        {
          text: this.$t('webPageManage.documentRequest.BillingMaterial'),
          sortable: false,
          value: 'ForVMForVS',
          width: '100',
        },
        {
          text: this.$t('webPageManage.documentRequest.importMethod'),
          sortable: false,
          value: 'webRequest.importMethod',
          width: '100',
        },
      ],
      numLength: 0,
      itemStart: 0,
      itemStop: 0,
      itemsEditSelected: [],
    };
  },
  props: {
    visible: Boolean,
    itemsSuggestPic: Array,
    suggestClientPIC: Array,
  },
  computed: {
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.closeDialog();
        }
      },
    },
  },
  methods: {
    ...mapActions(['actionConfirmClientPic']),
    getNumOfPage(pagination) {
      this.numLength = pagination.itemsLength;
      this.itemStart = pagination.pageStart + 1;
      this.itemStop = pagination.pageStop;
    },

    updateItemsEditSelected(value) {
      const checkExist = this.checkExist(value);
      if (checkExist) {
        const objIndex = this.itemsEditSelected.findIndex(
          (i) =>
            i.webRequestId === value.webRequest.id &&
            i.picStaffId === value.suggestedPic.id,
        );
        if (objIndex) {
          this.itemsEditSelected[objIndex].picStaffName =
            value.suggestedPic.name;
        }
      } else {
        this.itemsEditSelected.push({
          webRequestId: value.webRequest.id,
          picStaffName: value.suggestedPic.name,
          picStaffId: value.suggestedPic.id,
        });
      }
    },

    checkExist(value) {
      var i;
      for (i = 0; i < this.itemsEditSelected.length; i++) {
        if (
          this.itemsEditSelected[i].webRequestId === value.webRequest.id &&
          this.itemsEditSelected[i].picStaffId === value.suggestedPic.id
        ) {
          return true;
        }
      }

      return false;
    },
    save() {
      if (this.suggestClientPIC.length !== 0) {
        const dataConfirmClientPicArr = [];
        this.suggestClientPIC.forEach((element) => {
          dataConfirmClientPicArr.push({
            webRequestId: element.webRequest.id,
            picStaffId: element.suggestedPic.id,
          });
        });

        this.actionConfirmClientPic({
          assignments: dataConfirmClientPicArr,
        }).then(() => {
          this.closeDialog();
        });
      }
    },
    closeDialog() {
      this.$emit('close');
      this.$emit('setSuggestClientPIC', []);
    },
    formatDate(date) {
      return moment(date).format('MM/YY');
    },
  },
};
</script>

<style lang="scss" scoped>
// -- Dialog --
body {
  overflow: hidden;
}
h1 {
  color: #000000 !important;
  font-size: 22px !important;
  font-weight: bold !important;
}
.v-btn{
  height: 36px !important;
  color: #fff !important;
}
.t-btn--red-dark{
  width: 85px !important;
}
.t-btn--prm{
  background: #1873D9 !important;
  width: 110px !important;
}
::v-deep {
  .v-dialog {
    width: 1500px;
    background-color: white;
    padding: 30px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
  .v-data-table-header {
    tr {
      th {
        font-size: 14px !important;
        color: #757575 !important;
        font-weight: bold !important;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: 16px !important;
        color: #757575 !important;
        font-weight: 400 !important;
      }
    }
  }
  .v-data-footer__select {
    font-size: 13px !important;
    color: #000000;
    font-weight: 500;
    .v-select__selection--comma {
      font-size: 13px !important;
      color: #000000;
      font-weight: 500;
    }
    .v-input__icon--append {
      .v-icon__svg {
        color: #333333 !important;
      }
    }
  }
  .v-data-footer__pagination {
    font-size: 13px !important;
    color: #000000;
    font-weight: 500;
  }
  .v-data-table-header__icon {
    .v-icon__svg {
      color: #000000 !important;
    }
  }
  .v-data-footer__icons-before,
  .v-data-footer__icons-after{
    .v-icon__svg {
      color: #333333 !important;
    }
  }
}
.textCustomerSalesRepresentative {
  color: #006dab;
  font-weight: bolder;
}
.edit-suggested-pic-button {
  color: #006dab;
  font-weight: bolder;
  cursor: pointer;
}

// .custom-table {
//   ::v-deep {
//     .v-data-table__wrapper {
//       tbody > tr > td {
//         &:nth-child(1) {
//           max-width: 100px !important;
//           white-space: nowrap !important;
//           overflow: hidden !important;
//           text-overflow: ellipsis !important;
//         }
//         &:nth-child(2) {
//           max-width: 100px !important;
//           white-space: nowrap !important;
//           overflow: hidden !important;
//           text-overflow: ellipsis !important;
//         }
//         &:nth-child(4) {
//           max-width: 100px !important;
//           white-space: nowrap !important;
//           overflow: hidden !important;
//           text-overflow: ellipsis !important;
//         }
//         &:nth-child(5) {
//           max-width: 100px !important;
//           white-space: nowrap !important;
//           overflow: hidden !important;
//           text-overflow: ellipsis !important;
//         }
//       }
//     }
//   }
// }
</style>
