<template>
  <v-dialog
    v-model="visibleDetail"
    @click:outside="closeDialog"
    content-class="pa-5"
  >
    <div class="flex-between">
      <h2 class="text--big mb-4">
        {{ getCampaignDetail.name }}
      </h2>
      <v-btn class="t-btn--red-dark" @click="closeDialog">
        <v-icon>mdi-close</v-icon>
        {{ $t('buttons.close') }}
      </v-btn>
    </div>

    <!-- List Data -->
    <v-row class="ma-0">
      <v-col cols="12" lg="3" md="6" class="pl-lg-0">
        <div class="t-wrapper elevation-3 rounded">
          <div class="t-table dialog-ad">
            <div>
              <label>ステータス</label>
              <strong>{{
                getCampaignDetail.status === 'Ended' ? '終了' : '対応中'
              }}</strong>
            </div>
            <div>
              <label>広告施策ID</label>
              <strong>{{ getCampaignDetail.id }}</strong>
            </div>
            <div>
              <label>広告施策タグ</label>
              <strong>{{ showTextGroups(getCampaignDetail.groups) }}</strong>
            </div>
            <div>
              <label>広告施策期間</label>
              <strong>{{
                formatDate(getCampaignDetail.startDate) +
                  ' ~ ' +
                  formatDate(getCampaignDetail.endDate)
              }}</strong>
            </div>
            <div>
              <label>実施広告費用</label>
              <strong><tvos-jpy :value="getCampaignDetail.budget"/></strong>
            </div>
            <div>
              <label>発送資料データ</label>
              <div class="t-table__cell">
                <span
                  class="font-weight-bold d-block pdf-file"
                  :title="getCampaignDetail.attachment1"
                  @click="openPdf(getCampaignDetail.attachment1Url)"
                  >{{ getCampaignDetail.attachment1 | filename }}</span
                >
                <!-- process.env.URL + link-->
                <span
                  class="font-weight-bold d-block pdf-file"
                  :title="getCampaignDetail.attachment2"
                  @click="openPdf(getCampaignDetail.attachment2Url)"
                  >{{ getCampaignDetail.attachment2 | filename }}</span
                >
                <!-- process.env.URL + link-->
                <span
                  class="font-weight-bold d-block pdf-file"
                  :title="getCampaignDetail.attachment3"
                  @click="openPdf(getCampaignDetail.attachment3Url)"
                  >{{ getCampaignDetail.attachment3 | filename }}</span
                >
                <!-- process.env.URL + link-->
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" lg="2" md="6">
        <div class="t-wrapper elevation-3 rounded">
          <div class="t-table dialog-ad">
            <div>
              <label>反応総数</label>
              <strong
                >{{
                  getCampaignDetail.computed
                    ? getCampaignDetail.computed.responseCount
                    : ''
                }}{{ $t('commons.item') }}</strong
              >
            </div>
            <div>
              <label>反応CPA</label>
              <strong
                ><tvos-jpy
                  :value="
                    showRoundNumber(
                      getCampaignDetail.budget,
                      getCampaignDetail.computed
                        ? formatCurrency(
                            getCampaignDetail.computed.responseCount,
                          )
                        : '',
                    )
                  "
              /></strong>
            </div>
            <div>
              <label>新規</label>
              <strong
                >{{
                  getCampaignDetail.detailComputed
                    ? getCampaignDetail.detailComputed.newClientCount
                    : ''
                }}{{ $t('commons.item') }}</strong
              >
            </div>
            <div>
              <label>再反応</label>
              <strong
                >{{
                  `${(getCampaignDetail.computed
                    ? getCampaignDetail.computed.responseCount
                    : '') -
                    (getCampaignDetail.detailComputed
                      ? getCampaignDetail.detailComputed.newClientCount
                      : '')}`
                }}{{ $t('commons.item') }}</strong
              >
            </div>
            <div>
              <label>成約数</label>
              <strong
                >{{
                  getCampaignDetail.computed
                    ? getCampaignDetail.computed.contractCount
                    : ''
                }}{{ $t('commons.item') }}</strong
              >
            </div>
            <div>
              <label>成約CPA</label>
              <strong
                ><tvos-jpy
                  :value="
                    getCampaignDetail.computed
                      ? showRoundNumber(
                          getCampaignDetail.budget,
                          getCampaignDetail.computed.contractCount,
                        )
                      : ''
                  "
              /></strong>
            </div>
            <div>
              <label>VS</label>
              <strong
                >{{
                  getCampaignDetail.detailComputed
                    ? getCampaignDetail.detailComputed.vsContractCount
                    : ''
                }}{{ $t('commons.item') }}</strong
              >
            </div>
            <div>
              <label>VM</label>
              <strong
                >{{
                  getCampaignDetail.detailComputed
                    ? getCampaignDetail.detailComputed.vmContractCount
                    : ''
                }}{{ $t('commons.item') }}</strong
              >
            </div>
            <div>
              <label>成約金額合計</label>
              <strong
                ><tvos-jpy
                  :value="
                    getCampaignDetail.detailComputed
                      ? getCampaignDetail.detailComputed.paymentTotal
                      : ''
                  "
              /></strong>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" lg="3" md="6">
        <div class="t-wrapper elevation-3 rounded">
          <div class="t-table dialog-ad">
            <div>
              <label>{{ $t('commons.target') }}</label>
              <strong
                >{{
                  showTarget({
                    target20s: getCampaignDetail.target20s,
                    target30s: getCampaignDetail.target30s,
                    target40s: getCampaignDetail.target40s,
                    target50s: getCampaignDetail.target50s,
                    target60s: getCampaignDetail.target60s,
                    target70s: getCampaignDetail.target70s,
                    targetMale: getCampaignDetail.targetMale,
                    targetFemale: getCampaignDetail.targetFemale,
                  })
                }}
              </strong>
            </div>
            <div>
              <label>{{ $t('commons.purpose') }}</label>
              <strong
                >{{
                  showTextPurpose({
                    purposeRecognition: getCampaignDetail.purposeRecognition,
                    purposePersuade: getCampaignDetail.purposePersuade,
                    purposePromote: getCampaignDetail.purposePromote,
                  })
                }}
              </strong>
            </div>
            <div>
              <label>{{ $t('advertising.policies.method') }}</label>
              <strong>{{
                showTextMethods({
                  methodDm: getCampaignDetail.methodDm,
                  methodEmail: getCampaignDetail.methodEmail,
                  methodBillboard: getCampaignDetail.methodBillboard,
                  methodTv: getCampaignDetail.methodTv,
                  methodSns: getCampaignDetail.methodSns,
                  methodWeb: getCampaignDetail.methodWeb,
                })
              }}</strong>
            </div>
            <div>
              <label>{{
                $t('advertising.policies.targetEchoMediumSelection')
              }}</label>
              <div class="t-table__cell">
                <div class="d-flex flex-column">
                  <strong>{{
                    showTextMedia(getCampaignDetail.mediaTypes)
                  }}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" lg="4" md="6" class="pr-lg-0">
        <div class="t-wrapper rounded elevation-3">
          <div class="t-table --text">
            <strong>{{ $t('commons.remark') }}</strong>
            <br />
            <strong class="word-break">
              <tvos-format-span :value="getCampaignDetail.note" />
            </strong>
            <div class="text-center mt-8 d-block">
              <v-btn
                @click="editCamInDetail"
                :disabled="!checkUser"
                class="t-btn--prm"
                >{{ $t('buttons.editAdvertisingPolicyContent') }}</v-btn
              >
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- End - List data -->

    <!--  Customer data table -->
    <div class="elevation-2 mt-5">
      <div class="d-flex align-end pa-5">
        <h3 class="page-title-list mr-8" ref="turnOffMenu">
          {{ $t('advertising.policies.detailFirstHeading') }}
        </h3>
      </div>
      <Table
        :headers="headerCustomerTable"
        :items="getApplyResponsesDetailCam"
        :attr="{
          dense: true,
          'no-data-text': $t('rules.noData'),
          'item-key': 'id',
          class: 'dialog-ad',
        }"
        :itemsPerPage="10"
        :itemsPerPageOptions="[10, 20, 50, 100, 200, 500]"
        ref="table"
      >
        <template v-slot:[`item.responseAt`]="{ item }">
          <v-menu bottom offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                :disabled="!checkUser"
                :color="item.responseAt ? '#F5511E' : '#9E9E9E'"
                class="btnStatus w-100 btn"
                v-bind="attrs"
                v-on="on"
                ><span>
                  {{ item.responseAt ? '反応有' : 'なし' }}
                </span>
              </v-btn>
            </template>

            <v-list>
              <div class="px-2 div-select-dialog">
                <v-select
                  dense
                  class="my-1"
                  v-model="item.responseAtStatus"
                  item-value="id"
                  item-text="name"
                  :items="getShowStatusCam"
                  @change="
                    changeStatusClientCam(item.id, item.responseAtStatus)
                  "
                >
                </v-select>
              </div>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{
            item.createdAt
              ? new Date(item.createdAt)
                  .toISOString()
                  .substr(0, 10)
                  .replace(/-/g, '/')
              : ''
          }}
        </template>
        <template v-slot:[`item.detail`]="{ item }">
          <v-btn small icon class="t-btn--prm">
            <v-icon small @click="redirectPage(item, 'client')"
              >mdi-human-handsup</v-icon
            >
          </v-btn>
        </template>
        <template v-slot:[`item.delete`]="{ item }">
          <v-btn
            small
            icon
            :disabled="!checkUser"
            class="t-btn--red-dark"
            :style="checkUser ? '' : 'background-color: #bdbdbd !important'"
          >
            <v-icon small @click="deleteCamAppResponse(item.id)"
              >mdi-delete</v-icon
            >
          </v-btn>
        </template>
      </Table>
    </div>
    <!--  End - Customer data table -->

    <!--  Contract data table -->
    <div class="elevation-2 mt-5">
      <h3 class="page-title-list pa-5">
        {{ $t('advertising.policies.detailSecondHeading') }}
      </h3>
      <Table
        :headers="headerContractTable"
        :items="getContractsDetailCam"
        :attr="{
          dense: true,
          'no-data-text': $t('rules.noData'),
          'item-key': 'id',
          class: 'table-custom',
        }"
        :itemsPerPage="10"
        :itemsPerPageOptions="[10, 20, 50, 100, 200, 500]"
        ref="table"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          {{
            item.createdAt
              ? new Date(item.createdAt)
                  .toISOString()
                  .substr(0, 10)
                  .replace(/-/g, '/')
              : ''
          }}
        </template>
        <template v-slot:[`item.contractDetail`]="{ item }">
          <v-btn
            small
            icon
            class="t-btn--prm"
            @click="redirectPage(item, 'contract')"
          >
            <v-icon small>mdi-card-account-details</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.detail`]="{ item }">
          <v-btn small icon class="t-btn--prm">
            <v-icon small @click="redirectPage(item, 'client')"
              >mdi-human-handsup</v-icon
            >
          </v-btn>
        </template>
      </Table>
    </div>
    <!--  End - Contract data table -->

    <!-- Advertising measures CSV Customer batch linking -->
    <v-card class="mt-4 px-5 py-5">
      <h3 class="page-title-list mr-8">
        広告施策顧客一括紐付け
      </h3>
      <v-form ref="formArrClientBulk">
        <div class="mt-3">
          <template>
            <v-textarea
              class="mt-2 rounded-lg"
              rows="5"
              outlined
              clearable
              v-model="arrClientId"
              :rules="[arrClientBlank]"
              @keypress="isNumberClient($event)"
              :disabled="!checkUser"
            ></v-textarea>
          </template>
        </div>
      </v-form>
      <div class="d-flex justify-start">
        <v-btn
          class="t-btn--prm ml-8 mr-3"
          :disabled="!checkUser"
          @click="createBulkCampaignApplyResponse(false)"
        >
          一括紐付け
        </v-btn>
        <v-btn
          class="t-btn--prm"
          :disabled="!checkUser"
          @click="createBulkCampaignApplyResponse(true)"
        >
          反応有りで一括紐付け
        </v-btn>
      </div>
    </v-card>
    <!-- End Advertising measures CSV Customer batch linking -->

    <AdvertisingPoliciesDialogEdit
      v-if="openDialogEdit"
      :visible="openDialogEdit"
      @close="openDialogEdit = false"
      :policy-cam-current="getCampaignDetail"
      :idPolicy="idPolicy"
      @fetchList="getDetailPolicy(idPolicy)"
      className="--child"
      @reload="
        () => {
          $emit('fetchList');
        }
      "
    />
  </v-dialog>
</template>

<script>
import { checkPermissionUserCurrent } from '@/utils/permissions';
import AdvertisingPoliciesDialogEdit from './advertisingPoliciesDialogEdit';
import {
  formatCurrency,
  handlErrorView,
  getCopyErrorTextView,
} from '@/constants/functions';
import {
  DETAIL_CAMPAIGN_ADV_POLICIES,
  UPDATE_CAMAPP_APPLY_RESPONSE,
  DELETE_CAMAPP_APPLY_RESPONSE,
  CREATE_BULK_CAMAPP_APPLY_RESPONSE,
} from '@/api/graphql/advertising/advertising';
import { mapGetters, mapMutations } from 'vuex';
import gql from 'graphql-tag';
import Table from '@/components/Table/index.vue';

export default {
  name: 'AdvertisingPoliciesDetailDialog',
  data() {
    return {
      checkUser: checkPermissionUserCurrent(this.$router.currentRoute),
      openDialogEdit: false,
      numLengthClient: 0,
      itemStartClient: 0,
      itemStopClient: 0,
      optionsClient: {
        sortBy: ['createdAt'],
        sortDesc: [false],
      },
      order: 0,
      headerSortCurrentClient: '',
      headerCustomerTable: [
        {
          text: '',
          value: '',
          width: '50px',
          sortable: false,
        },
        {
          text: this.$t('commons.reaction'),
          value: 'responseAt',
          width: '80px',
          sortable: true,
        },
        {
          text: this.$t('advertising.contractSpecial.company'),
          value: 'clientIsCompany',
          width: '110px',
          sortable: true,
        },
        {
          text: this.$t('commons.customerName'),
          value: 'clientName',
          width: '110px',
          sortable: true,
        },
        {
          text: this.$t('advertising.policies.corporateContactPerson'),
          value: 'companyPicName',
          width: '140px',
          sortable: true,
        },
        {
          text: this.$t('advertising.policies.corporateContactPersonTitle'),
          value: 'companyPicTitle',
          width: '170px',
          sortable: true,
        },
        {
          text: this.$t('commons.latestActivityHistory'),
          value: 'lastClientActionHistoryRemarks',
          width: '130px',
          sortable: true,
        },
        {
          text: this.$t('commons.dateAdded'),
          value: 'createdAt',
          width: '80px',
          sortable: true,
        },
        {
          text: this.$t('customerDetail.infoEdit.saleStaff'),
          value: 'clientPicName',
          width: '100px',
          sortable: true,
        },
        {
          text: this.$t('webPageManage.infoRequest.customerDetail'),
          value: 'detail',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('buttons.delete'),
          value: 'delete',
          align: 'center',
          sortable: false,
        },
      ],

      numLengthContract: 0,
      itemStartContract: 0,
      itemStopContract: 0,
      optionsContract: {
        sortBy: ['createdAt'],
        sortDesc: [false],
      },
      orderContract: 0,
      headerSortCurrentContract: '',
      headerContractTable: [
        {
          text: this.$t('commons.dateAdded'),
          value: 'createdAt',
          width: '90px',
          sortable: true,
        },
        {
          text: this.$t('advertising.policies.contractStatus'),
          value: 'contractStatus',
          width: '140px',
          sortable: true,
        },
        {
          text: this.$t('commons.contractProduct'),
          value: 'contractProduct',
          width: '100px',
          sortable: true,
        },
        {
          text: this.$t('commons.phase'),
          value: 'phaseName',
          width: '100px',
          sortable: true,
        },
        {
          text: this.$t('advertising.policies.salesRepresentative'),
          value: 'salesStaff',
          width: '100px',
          sortable: true,
        },
        {
          text: this.$t('commons.contractDetail'),
          value: 'contractDetail',
          width: '100px',
          align: 'center',
          sortable: true,
        },
        {
          text: this.$t('commons.privateCorporation'),
          value: 'contractIsCompany',
          width: '110px',
          sortable: true,
        },
        {
          text: this.$t('commons.customerName'),
          value: 'customerName',
          width: '100px',
          sortable: true,
        },
        {
          text: this.$t('advertising.policies.corporateContactPerson'),
          value: 'companyPicName',
          width: '150px',
          sortable: true,
        },
        {
          text: this.$t('advertising.policies.corporateContactPersonTitle'),
          value: 'companyPicTitle',
          width: '170px',
          sortable: true,
        },
        {
          text: this.$t('webPageManage.infoRequest.customerDetail'),
          value: 'detail',
          align: 'center',
          sortable: false,
        },
      ],
      listCustomerTable: [],
      listContractTable: [],
      listPurpose: {
        purposeRecognition: this.$t('advertising.policies.awarenessExpansion'),
        purposePersuade: this.$t('advertising.policies.harvestingPolicy'),
        purposePromote: this.$t('advertising.policies.promotionOfUse'),
      },
      listMethod: {
        methodDm: 'DM',
        methodEmail: this.$t('advertising.policies.emailNewsletter'),
        methodBillboard: this.$t('advertising.policies.signboard'),
        methodTv: 'TV',
        methodSns: 'SNS',
        methodWeb: 'WEB',
      },
      arrClientId: '',
      arrClientBlank(v) {
        return !!v || '必須項目です。';
      },
    };
  },
  props: {
    idPolicy: [Number],
  },
  watch: {
    optionsClient: {
      handler() {
        this.sortClient();
      },
      deep: true,
    },
    optionsContract: {
      handler() {
        this.sortContract();
      },
      deep: true,
    },
  },
  created() {
    this.formatDate();
    if (this.idPolicy) {
      this.getDetailPolicy(this.idPolicy);
    }
  },
  computed: {
    visibleDetail() {
      return true;
    },
    ...mapGetters([
      'getCampaignDetail',
      'getEnumCampaignMediaTypeList',
      'getShowStatusCam',
      'getApplyResponsesDetailCam',
      'getContractsDetailCam',
    ]),
  },
  methods: {
    openPdf(url) {
      window.open(url);
    },
    formatDate(date) {
      if (date) {
        return date.replace(/-/g, '/');
      }
    },
    formatCurrency,
    handlErrorView,
    getCopyErrorTextView,
    ...mapMutations([
      'setCopyErrorText',
      'setAlertSuccess',
      'setAlertError',
      'setLoadingOverlayShow',
      'setLoadingOverlayHide',
      'setDetailPolicy',
      'setCampaignMediaTypeList',
      'setPermissionUser',
      'setRoleAdminUser',
    ]),
    closeDialog() {
      this.setDetailPolicy({ campaignDetail: {} });
      this.$refs.formArrClientBulk.resetValidation();
      // this.$emit('fetchList');
      this.$emit('close');
    },
    getNumOfPageClient(pagination) {
      this.numLengthClient = pagination.itemsLength;
      this.itemStartClient = pagination.pageStart + 1;
      this.itemStopClient = pagination.pageStop;
    },
    getNumOfPageContract(pagination) {
      this.numLengthContract = pagination.itemsLength;
      this.itemStartContract = pagination.pageStart + 1;
      this.itemStopContract = pagination.pageStop;
    },

    // detail
    showTextLength(allText = '', lengthCheck = 100, lengthCut = 10) {
      if (allText && allText.length >= lengthCheck) {
        return `${allText.substr(0, lengthCut)}...${allText.substr(-5)}`;
      }
      return '';
    },
    showTarget(targeCam = null) {
      if (targeCam) {
        const textTarget = [];
        Object.keys(targeCam).forEach(function(key) {
          if (key !== 'targetMale' && key !== 'targetFemale' && targeCam[key]) {
            textTarget.push(`${parseInt(key.substring(6), 10)}代`);
          }
          if (key === 'targetMale' && targeCam[key]) {
            textTarget.push(`男性`);
          }
          if (key === 'targetFemale' && targeCam[key]) {
            textTarget.push(`女性`);
          }
        });
        return textTarget.join(',');
      }
      return '';
    },
    showTextPurpose(purposeCam = null) {
      if (purposeCam) {
        const textPurpose = [];
        Object.keys(purposeCam).forEach(key => {
          if (purposeCam[key]) {
            textPurpose.push(this.listPurpose[key]);
          }
        });
        return textPurpose.join(',');
      }
      return '';
    },
    showTextGroups(groupCam = null) {
      if (groupCam && groupCam.length > 0) {
        return groupCam.join(',');
      }
      return '';
    },
    showTextMethods(methodsCam = null) {
      if (methodsCam) {
        const textMethods = [];
        Object.keys(methodsCam).forEach(key => {
          if (methodsCam[key]) {
            textMethods.push(this.listMethod[key]);
          }
        });
        return textMethods.join(',');
      }
      return '';
    },
    showTextMedia(mediaCam = null) {
      if (mediaCam && mediaCam.length > 0) {
        return mediaCam.map(item => item.name).join(',');
      }
      return '';
    },
    showRoundNumber(number1, number2) {
      if (number2 && number2 !== 0) {
        const subNumber = number1 / number2;
        if (subNumber <= 4) {
          return Math.floor(subNumber);
        } else {
          return Math.ceil(subNumber);
        }
      }
      return '';
    },
    async changeStatusClientCam(idClient, statusresponseAt) {
      if (parseInt(idClient) && parseInt(statusresponseAt)) {
        const variables = {
          id: parseInt(idClient),
          hasResponse: statusresponseAt === 1,
        };
        this.setLoadingOverlayShow();
        await this.$apollo
          .mutate({
            mutation: gql`
              ${UPDATE_CAMAPP_APPLY_RESPONSE}
            `,
            variables: variables,
            fetchPolicy: 'no-cache',
          })
          .then(data => {
            this.setLoadingOverlayHide();
            if (data.data.updateCampaignApplyResponse.id) {
              this.getDetailPolicy(this.idPolicy);
              this.setAlertSuccess('更新しました。');
              this.$refs.turnOffMenu.click();
            }
          })
          .catch(async error => {
            this.setLoadingOverlayHide();
            this.setCopyErrorText(
              this.getCopyErrorTextView(
                UPDATE_CAMAPP_APPLY_RESPONSE,
                variables,
                error.graphQLErrors,
              ),
            );
            const errorTmp = await handlErrorView(
              error.graphQLErrors,
              this.setPermissionUser,
              this.setRoleAdminUser,
            );
            if (errorTmp) {
              this.setAlertError(errorTmp, { root: true });
            } else {
              this.setAlertError('更新失敗');
            }
          });
      }
    },
    turnOffMenu() {},
    redirectPage(item = null, page = null) {
      if (item && page) {
        if (page === 'client') {
          const routeDataClient = this.$router.resolve({
            name: 'customer-details',
            params: { id: item.client.id },
            query: { idClient: item.client.id },
          });
          window.open(routeDataClient.href, '_blank');
        }
        if (page === 'contract') {
          const routeDataContract = this.$router.resolve({
            path: `/contract/basic-infomation/${item.client.id}/${item.productType.code}`,
          });
          window.open(routeDataContract.href, '_blank');
        }
      }
    },
    deleteCamAppResponse(id) {
      if (id) {
        this.$swal
          .fire({
            title: '削除しますか？',
            showCancelButton: true,
            confirmButtonText: '削除',
            confirmButtonColor: '#ff5252',
            cancelButtonText: '閉じる',
            cancelButtonColor: '#1976d2',
            reverseButtons: true,
          })
          .then(async result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              this.setLoadingOverlayShow();
              const variables = {
                id: parseInt(id),
              };
              await this.$apollo
                .mutate({
                  mutation: gql`
                    ${DELETE_CAMAPP_APPLY_RESPONSE}
                  `,
                  variables: variables,
                  fetchPolicy: 'no-cache',
                })
                .then(data => {
                  this.setLoadingOverlayHide();
                  if (data.data.deleteCampaignApplyResponse) {
                    this.getDetailPolicy(this.idPolicy);
                    this.setAlertSuccess('削除しました。');
                  }
                })
                .catch(async error => {
                  this.setLoadingOverlayHide();
                  this.setCopyErrorText(
                    this.getCopyErrorTextView(
                      DELETE_CAMAPP_APPLY_RESPONSE,
                      variables,
                      error.graphQLErrors,
                    ),
                  );
                  const errorTmp = await handlErrorView(
                    error.graphQLErrors,
                    this.setPermissionUser,
                    this.setRoleAdminUser,
                  );
                  if (errorTmp) {
                    this.setAlertError(errorTmp, { root: true });
                  } else {
                    this.setAlertError('削除に失敗しました');
                  }
                });
            }
          });
      }
    },
    isNumberClient: function(evt) {
      evt = evt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode > 44 &&
          charCode < 58 &&
          charCode !== 47 &&
          charCode !== 46 &&
          charCode !== 45) ||
        charCode === 13
      ) {
        return true;
      } else {
        evt.preventDefault();
      }
    },
    async createBulkCampaignApplyResponse(status = false) {
      if (this.$refs.formArrClientBulk.validate()) {
        this.setLoadingOverlayShow();
        const variables = {
          clientIds: this.arrClientId.split('\n').map(Number),
          campaignId: parseInt(this.idPolicy),
          status: status,
        };
        await this.$apollo
          .mutate({
            mutation: gql`
              ${CREATE_BULK_CAMAPP_APPLY_RESPONSE}
            `,
            variables: variables,
            fetchPolicy: 'no-cache',
          })
          .then(data => {
            this.setLoadingOverlayHide();
            if (data.data.createBulkCampaignApplyResponse) {
              this.getDetailPolicy(this.idPolicy);
              this.setAlertSuccess('作成しました。');
            }
          })
          .catch(async error => {
            this.setLoadingOverlayHide();
            this.setCopyErrorText(
              this.getCopyErrorTextView(
                CREATE_BULK_CAMAPP_APPLY_RESPONSE,
                variables,
                error.graphQLErrors,
              ),
            );
            const errorTmp = await handlErrorView(
              error.graphQLErrors,
              this.setPermissionUser,
              this.setRoleAdminUser,
            );
            if (errorTmp) {
              this.setAlertError(errorTmp, { root: true });
            } else {
              this.setAlertError('新しいものを作成できません');
            }
          });
      }
    },
    async getDetailPolicy(idPolicy) {
      this.setLoadingOverlayShow();
      const variables = {
        campaignId: parseInt(idPolicy),
      };
      await this.$apollo
        .query({
          query: gql`
            ${DETAIL_CAMPAIGN_ADV_POLICIES}
          `,
          variables: variables,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
          this.setLoadingOverlayHide();
          if (data.data.campaign.id) {
            if (data.data.campaign.applyResponses.length > 0) {
              data.data.campaign.applyResponses.map(item => {
                // process convert data applyResponses
                if (item.responseAt) {
                  item.responseAtStatus = 1;
                } else {
                  item.responseAtStatus = 2;
                }
                if (item.client.isCompany) {
                  item.clientIsCompany = '法人';
                } else {
                  item.clientIsCompany = '個人';
                }
                item.clientName = item.client.name;
                item.companyPicName = item.client.companyPicName;
                item.companyPicTitle = item.client.companyPicTitle;
                item.clientPicName = item.client.pic
                  ? item.client.pic.name
                  : '';
                item.lastClientActionHistoryRemarks = item.client
                  .lastClientActionHistory
                  ? item.client.lastClientActionHistory.remarks
                  : '';
                return item;
              });
            }
            if (data.data.campaign.contracts.length > 0) {
              data.data.campaign.contracts.map(item => {
                // process convert data contracts
                if (item.client.isCompany) {
                  item.contractIsCompany = '法人';
                } else {
                  item.contractIsCompany = '個人';
                }
                item.contractStatus = item.status;
                item.contractProduct = item.productType.code;
                item.phaseName = item.phase ? item.phase.code : '';
                item.salesStaff = item.salesStaff ? item.salesStaff.name : '';
                item.customerName = item.client.name;
                item.companyPicName = item.client.companyPicName;
                item.companyPicTitle = item.client.companyPicTitle;
                item.lastClientActionHistoryRemarks = item.client
                  .lastClientActionHistory
                  ? item.client.lastClientActionHistory.remarks
                  : '';
                return item;
              });
            }
            this.setDetailPolicy({ campaignDetail: data.data.campaign });
          }
          this.$nextTick(() => {
            this.$refs.formArrClientBulk.resetValidation();
          });
        })
        .catch(async error => {
          this.setLoadingOverlayHide();
          this.setCopyErrorText(
            this.getCopyErrorTextView(
              DETAIL_CAMPAIGN_ADV_POLICIES,
              variables,
              error.graphQLErrors,
            ),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },
    sortClient() {
      this.order = 1;
      if (typeof this.optionsClient.sortDesc[0] === 'undefined') {
        this.optionsClient.sortDesc[0] = false;
        this.order = 0;
      }
      if (this.optionsClient.sortDesc[0] === false) {
        this.order = 0;
      }
      if (this.optionsClient.sortBy[0]) {
        this.headerSortCurrentClient = this.optionsClient.sortBy[0];
      } else {
        this.optionsClient.sortBy[0] = this.headerSortCurrentClient;
      }
    },
    sortContract() {
      this.orderContract = 1;
      if (typeof this.optionsContract.sortDesc[0] === 'undefined') {
        this.optionsContract.sortDesc[0] = false;
        this.orderContract = 0;
      }
      if (this.optionsContract.sortDesc[0] === false) {
        this.orderContract = 0;
      }
      if (this.optionsContract.sortBy[0]) {
        this.headerSortCurrentContract = this.optionsContract.sortBy[0];
      } else {
        this.optionsContract.sortBy[0] = this.headerSortCurrentContract;
      }
    },
    editCamInDetail() {
      this.openDialogEdit = true;
    },
  },
  components: {
    AdvertisingPoliciesDialogEdit,
    Table,
  },
};
</script>

<style lang="scss" scoped>
.pdf-file {
  color: #009ce6;
  text-decoration: underline;
  cursor: pointer;
}
::v-deep {
  .v-data-footer {
    &__pagination {
      display: none !important;
    }
  }

  .v-dialog {
    background-color: var(--bg_white);
    max-width: 1400px;
  }
  .sort-select {
    max-width: 180px;
  }
  .v-chip {
    width: 100%;
    color: var(--text_white);
    justify-content: center;
  }
  .dialog-ad {
    label {
      color: #757575;
      font-weight: 600;
      white-space: nowrap;
    }
    strong {
      color: #424242;
      font-weight: 600;
    }
    &.v-data-table .v-data-table__wrapper table {
      th,
      td {
        color: #757575 !important;
      }
      .btn {
        color: #fff !important;
        border-radius: 6px;
        padding: 0 !important;
      }
    }
  }
}
.t-wrapper {
  height: 100%;
  background-color: var(--bg_white);
}
.t-table {
  border-spacing: 0 10px;
  padding: 16px;
  &:not(.text) {
    display: table;
    width: 100%;
    > div {
      display: table-row;
      label,
      strong,
      .t-table__cell {
        display: table-cell;
      }
      strong,
      .t-table__cell {
        text-align: right;
      }
    }
  }
}
a.d-block {
  color: var(--text_primary);
}
</style>
