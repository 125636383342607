<template>
  <div>
    <v-dialog v-model="dialog">
      <div class="justify-space-between d-flex">
        <h1>営業担当割振</h1>
      </div>
      <v-form ref="form" v-model="valid" lazy-validation>
        <div class="mt-3">
          <div>
            <v-select
              label="当日出勤"
              :items="itemsData.staffList"
              item-text="name"
              item-value="id"
              v-model="dataSelected.staffIds"
              multiple
              :disabled="!checkPerUser"
              :rules="[
                (v) => (v && v.length > 0) || this.$t('rules.requiredField'),
              ]"
            >
            </v-select>
          </div>
          <div>
            <v-text-field
              class="text-input-right"
              label="契約手続き中・Good・Coolの管理上限"
              v-model="dataSelected.maxClients"
              :rules="rules.maxClients"
              :disabled="!checkPerUser"
            ></v-text-field>
          </div>
          <div class="justify-end d-flex">
            <v-btn large class="t-btn--red-dark mr-2" @click="closeDialog">
              <v-icon color="#FFFFFF"> mdi-close </v-icon>
              閉じる
            </v-btn>
            <v-btn
              :disabled="!checkPerUser"
              large
              class="t-btn--prm"
              @click="handleAllocationExecution"
              >割り振り実行</v-btn
            >
          </div>
        </div>
      </v-form>
      <allocation-result
        v-if="checkPerUser"
        :visible="dialogAllocationResult === 1"
        @close="dialogAllocationResult = -1"
        :itemsSuggestPic="itemsSuggestPic"
        :suggestClientPIC="suggestClientPIC"
        @setSuggestClientPIC="setSuggestClientPIC"
      ></allocation-result>
    </v-dialog>
  </div>
</template>

<script>
import AllocationResult from './AllocationResult.vue';
import { mapGetters, mapActions } from 'vuex';
import { checkNumber } from '@/utils/validate';
import { checkPermissionUserCurrent } from '@/utils/permissions';

export default {
  components: { AllocationResult },
  name: 'SalesRepresentativeAllocation',
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      valid: true,
      rules: {
        maxClients: [
          (v) => !!v || this.$t('rules.requiredField'),
          (v) => (v && checkNumber(v)) || !v || this.$t('rules.numberInvalid'),
          (v) =>
            (v && v <= 2147483647) || !v || this.$t('rules.maximumNumberInt32'),
        ],
      },
      dialogAllocationResult: -1,

      itemsData: {
        staffList: [],
      },

      dataSelected: {
        staffIds: [],
        maxClients: null,
      },
      suggestClientPIC: [],
      itemsSuggestPic: [],
    };
  },
  props: {
    visible: Boolean,
    webRequestListDocumentRequestListSelected: Array,
  },
  computed: {
    ...mapGetters(['getWebRequestIds']),
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
          this.$refs.form.reset();
        }
      },
    },
  },
  mounted() {
    this.actionStaffList().then((staffList) => {
      this.itemsData.staffList = staffList;
    });
  },
  methods: {
    ...mapActions([
      'actionStaffList',
      'actionDuplicatedClientList',
      'actionSuggestClientPic',
    ]),
    handleAllocationExecution() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        const webRequestIds = [];

        this.webRequestListDocumentRequestListSelected.forEach((element) => {
          webRequestIds.push(element.id);
        });

        const data = {
          maxClients: parseInt(this.dataSelected.maxClients),
          staffIds: this.dataSelected.staffIds,
          webRequestIds: webRequestIds,
        };
        this.actionSuggestClientPic(data).then((suggestClientPIC) => {
          this.suggestClientPIC = suggestClientPIC;
          this.suggestClientPIC.forEach((element) => {
            this.itemsSuggestPic.push({
              picStaffId: element.suggestedPic.id,
              picStaffName: element.suggestedPic.name,
              webRequestId: element.webRequest.id,
            });
          });
        });
        this.dialogAllocationResult = 1;
      } else {
      }
    },
    closeDialog() {
      this.$emit('close');
      this.$refs.form.reset();
    },
    setSuggestClientPIC(value) {
      this.suggestClientPIC = value;
    },
  },
};
</script>

<style lang="scss" scoped>
// -- Dialog --
body {
  overflow: hidden;
}
h1 {
  font-size: 22px !important;
  font-weight: bold !important;
  color: #000000 !important;
}
.t-btn--red-dark{
  height: 32px !important;
  width: 78px !important;
}
.t-btn--prm{
  height: 32px !important;
  width: 110px !important;
  background: #1873D9 !important;
  color: #fff !important;
}
::v-deep {
  .v-dialog {
    width: 350px;
    background-color: white;
    padding: 30px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 10px !important;
      font-weight: 600 !important;
      color: #000000 !important;
    }
    .v-select__selections {
      .v-select__selection--comma {
        font-size: 10px !important;
        font-weight: 500 !important;
        color: #000000 !important;
      }
    }
    .v-text-field__slot{
      input{
        font-size: 10px !important;
        font-weight: 500 !important;
        color: #000000 !important;
      }
    }
    .v-icon__svg{
      color: #333 !important;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
}
</style>
