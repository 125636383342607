<template>
  <v-dialog v-model="visible" @click:outside="closeDialog" :content-class="className + ' pa-5'">
    <v-form ref="formCreateAdvert">
      <h2 class="text--big mb-4" style="color: #212121 !important; font-size: 28px !important;">{{ idPolicy ? $t('buttons.advertisingPolicyEdit') : $t('advertising.buttonAdd')}}</h2>
      <label style="color: #000000 !important;">{{ $t('commons.contractStatus') }}</label>
      <v-select
        :items="statusAdvert"
        v-model="statusAdvertSelected"
        dense
        :rules="[statusAdvertBlank]"
        item-text="name"
        item-value="id"
        class="mb-4"
      ></v-select>

      <v-row>
        <v-col cols="12" md="6">
          <label style="font-size: 10px !important;color: #000000 !important;">{{ $t('advertising.policies.advertisingName') }}</label>
          <v-text-field
            v-model="nameAdvert"
            class="pa-0 w-100 mb-4"
            :rules="[$rules.required, $rules.checkLenghInput(255)]"
          ></v-text-field>

          <label style="font-size: 10px !important;color: #000000 !important;">{{ $t('advertising.policies.groupName') }}</label>
          <v-combobox
            dense
            :items="getEnumCampaignGroupList"
            class="mb-4"
            multiple
            persistent-hint
            :rules="[groupSelectedBlank]"
            v-model="groupSelected"
           ></v-combobox>

          <!-- Date picker -->
          <label style="font-size: 10px !important;color: #000000 !important;">{{ $t('advertising.stage') }}</label>
          <v-menu
            ref="menu"
            class=" w-100"
            v-model="isShowDate"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex mb-4">
                <v-text-field
                  prepend-icon="mdi-calendar-month"
                  v-model="dateRangeText"
                  readonly
                  :rules="[dateRangerBlank]"
                  @click.stop="isShowDate = true"
                  v-bind="attrs"
                  v-on="on"
                  class="date-text pa-0 w-100"
                ></v-text-field>
              </div>
            </template>
            <v-date-picker
              v-model="dates"
              @change="formatDate($event)"
              no-title
              scrollable
              range
              :first-day-of-week="0"
              :locale="$i18n.locale"
              class="v-date-picker-custom"
            >
              <v-spacer></v-spacer>
              <v-btn class="t-btn--gray" @click="isShowDate = false">
                {{ $t('buttons.cancel') }}
              </v-btn>
              <v-btn
                class="t-btn--blue"
                @click="$refs.menu.save(dates)"
                :disabled="dates.length < 2"
              >
                {{ $t('buttons.save') }}
              </v-btn>
            </v-date-picker>
          </v-menu>
          <!-- End - datepicker -->

          <label style="font-size: 10px !important;color: #000000 !important;">{{ $t('advertising.policies.implemenCost') }}</label>
          <tvos-int-input v-model="implemenCost" jpy class="mb-9" />

          <!-- TARGET group -->
          <label style="font-size: 14px !important;color: #000000 !important;">{{ $t('commons.target') }}</label>
          <div class="d-flex mt-n3 flex-wrap mb-4">
            <v-checkbox
              v-for="item in listTarget"
              :key="item.value"
              v-model="targetSelected"
              :value="item.value"
              :label="item.label"
              hide-details
              class="mr-3"
            ></v-checkbox>
          </div>
          <!-- End - TARGET group -->

          <!-- PURPOSE group -->
          <label>{{ $t('commons.purpose') }}</label>
          <div class="d-flex mt-n3 flex-wrap mb-4">
            <v-checkbox
              v-for="item in listPurpose"
              :key="item.value"
              v-model="purposeSelected"
              :value="item.value"
              :label="item.label"
              hide-details
              class="mr-3"
            ></v-checkbox>
          </div>
          <!-- End - PURPOSE group-->

          <!-- METHOD group -->
          <label>{{ $t('advertising.policies.method') }}</label>
          <div class="d-flex mt-n3 flex-wrap mb-9">
            <v-checkbox
              v-for="item in listMethod"
              v-model="methodSelected"
              :key="item.value"
              :value="item.value"
              :label="item.label"
              hide-details
              class="mr-3"
            ></v-checkbox>
          </div>
          <!-- End - METHOD group-->

          <label style="font-size: 10px !important;color: #000000 !important;">{{ $t('advertising.policies.description') }}</label>
          <v-textarea
            class="mt-2 rounded-lg"
            rows="5"
            outlined
            v-model="textDescription"
            :rules="[$rules.checkTextAreaLength()]"
          ></v-textarea>
        </v-col>

        <v-col cols="12" md="6" class="pl-10">
          <!-- List input upload file -->
          <div
            class="d-flex justify-space-between mb-3"
          >
            <v-file-input
              v-model="fileAttach1"
              :rules="[uploadFileRules, fileType]"
              accept=".pdf"
              prepend-icon="mdi-file-upload"
              filled
              :title="policyCamCurrent.attachment1Url ? policyCamCurrent.attachment1Url : null"
              :placeholder="placeholderFile1"
              clearable
              @change="handleFileUpload($event, 'file1')"
            ></v-file-input>
            <a target="_blank" class="previewFile" :href="previewFileAtt(1)">
              <v-chip
                label
                color="var(--crm_primaryr_13ace0)"
                class="mt-4 previewFile"
                >{{ $t('commons.browsing') }}
                </v-chip>
            </a>
          </div>
          <div
            class="d-flex justify-space-between mb-3"
          >
            <v-file-input
              v-model="fileAttach2"
              :rules="[uploadFileRules, fileType]"
              accept=".pdf"
              clearable
              :title="policyCamCurrent.attachment2Url ? policyCamCurrent.attachment2Url : null"
              :placeholder="placeholderFile2"
              prepend-icon="mdi-file-upload"
              filled
              @change="handleFileUpload($event, 'file2')"
            ></v-file-input>
            <a target="_blank" class="previewFile" :href="previewFileAtt(2)">
              <v-chip
                label
                color="var(--crm_primaryr_13ace0)"
                class="mt-4 previewFile"
                >{{ $t('commons.browsing') }}
                </v-chip>
            </a>
          </div>
          <div
            class="d-flex justify-space-between mb-3"
          >
            <v-file-input
              v-model="fileAttach3"
              :rules="[uploadFileRules, fileType]"
              accept=".pdf"
              prepend-icon="mdi-file-upload"
              filled
              clearable
              :title="policyCamCurrent.attachment3Url ? policyCamCurrent.attachment3Url : null"
              :placeholder="placeholderFile3"
              @change="handleFileUpload($event, 'file3')"
            ></v-file-input>
            <a target="_blank" class="previewFile" :href="previewFileAtt(3)">
              <v-chip
                label
                color="var(--crm_primaryr_13ace0)"
                class="mt-4 previewFile"
                >{{ $t('commons.browsing') }}
                </v-chip>
            </a>
          </div>
          <!-- End - List input upload file -->

          <!-- Target response -->
          <label style="font-size: 14px !important;color: #000000 !important;">
            {{ $t('advertising.policies.selectTargetResponseMedia') }}
          </label>
          <div class="elevation-3 mt-2 pa-4 pb-3">
            <div class="mb-4">
              <div class="d-flex flex-wrap">
                <v-checkbox
                  v-for="(input,index) in getEnumCampaignMediaTypeList"
                  v-model="mediaTypeSelected"
                  :key="input.id"
                  :value="parseInt(input.id)"
                  :label="input.name"
                  class="mr-3"
                  :rules="validateCheckbox"
                  :hide-details="!isMediaType(index)"
                ></v-checkbox>
                
              </div>
            </div>
          </div>
          <!-- End - Target response -->
        </v-col>
      </v-row>

      <div class="text-right mt-5">
        <v-btn @click="resetForm" class="t-btn--red-dark">
          <v-icon>mdi-close</v-icon>
          {{ $t('buttons.close') }}
        </v-btn>
        <v-btn @click="submitForm" class="t-btn--prm ml-4">{{
          $t('buttons.save')
        }}</v-btn>
      </div>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { ENUM_CAMPAIGN_GROUP_LIST, ENUM_CAMPAIGN_MEDIA_TYPE_LIST, EDIT_CAMPAIGN_ADV_POLICIES, GENERATE_UPLOAD_URL } from '@/api/graphql/advertising/advertising';
import { handlErrorView, showTextLength, getCopyErrorTextView } from '@/constants/functions'
import axios from 'axios'
import gql from 'graphql-tag'
// import { CheckLenghInput } from '@/utils/validate';

export default {
  name: 'advertisingPoliciesDialogEdit',
  data() {
    return {
      isValid: true,
      isShowDate: false,
      uploadFileRules (v) { 
        return (!v || v.size <= 10000000) || '10MBを超えることはできません'
      },
      fileType (v) {
        return (!v || (v.type.includes('application/pdf'))) || '指定されたファイルの拡張子はサポートされていません'
      },
      statusAdvertBlank (v) {
        return !!v || '必須項目です。'
      },
      // nameAdvertBlank:[
      //   (v) => !!v || '必須項目です。',
      //   (v) => CheckLenghInput(v, 255) || this.$t('rules.maximumNCharacter', { value: 255 })
      // ],
      groupSelectedBlank (v) {
        return v.length > 0 || '必須項目です。'
      },
      dateRangerBlank (v) {
        return v.length > 0 ||  '必須項目です。'
      },
      implemenCost: 0,
      dates: [],
      targetSelected: [],
      listTarget: [
        {
          value: 'target20s',
          label: '20' + this.$t('commons.ageUnit'),
        },
        {
          value: 'target30s',
          label: '30' + this.$t('commons.ageUnit'),
        },
        {
          value: 'target40s',
          label: '40' + this.$t('commons.ageUnit'),
        },
        {
          value: 'target50s',
          label: '50' + this.$t('commons.ageUnit'),
        },
        {
          value: 'target60s',
          label: '60' + this.$t('commons.ageUnit'),
        },
        {
          value: 'target70s',
          label: '70' + this.$t('advertising.policies.overYearsOld'),
        },
        {
          value: 'targetFemale',
          label: this.$t('commons.female'),
        },
        {
          value: 'targetMale',
          label: this.$t('commons.male'),
        },
      ],
      purposeSelected: [],
      listPurpose: [
        {
          value: 'purposeRecognition',
          label: this.$t('advertising.policies.awarenessExpansion'),
        },
        {
          value: 'purposePersuade',
          label: this.$t('advertising.policies.harvestingPolicy'),
        },
        {
          value: 'purposePromote',
          label: this.$t('advertising.policies.promotionOfUse'),
        },
      ],
      methodSelected: [],
      listMethod: [
        {
          value: 'methodDm',
          label: 'DM',
        },
        {
          value: 'methodEmail',
          label: this.$t('advertising.policies.emailNewsletter'),
        },
        {
          value: 'methodBillboard',
          label: this.$t('advertising.policies.signboard'),
        },
        {
          value: 'methodTv',
          label: 'TV',
        },
        {
          value: 'methodSns',
          label: 'SNS',
        },
        {
          value: 'methodWeb',
          label: 'WEB',
        },
      ],
      textDescription: '',
      listFileUpload: [
        {
          nameDefault: 'xxx.pdf',
          file: null,
          status: true,
        },
        {
          nameDefault: null,
          file: null,
          status: false,
        },
        {
          nameDefault: null,
          file: null,
          status: false,
        },
      ],

      statusAdvert: [
        {
          id: 'Active', 
          name: '対応中'
        },
        {
          id: 'Ended', 
          name: '終了'
        },
      ],
      fileAttach1UploadStatus: false,
      fileAttach2UploadStatus: false,
      fileAttach3UploadStatus: false,
      fileAttach1: null,
      fileAttach2: null,
      fileAttach3: null,
      previewFile1: null,
      previewFile2: null,
      previewFile3: null,
      placeholderFile1: '',
      placeholderFile2: '',
      placeholderFile3: '',
      statusAdvertSelected: null,
      mediaTypeSelected:[],
      nameAdvert: '',
      groupSelected: []
    };
  },
  props: {
    className: {
      type: String,
      default: '',
    },
    idPolicy: {
      type: Number,
      default: 0,
      required: true,
    },
    policyCamCurrent: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    }
  },
  computed: {
     ...mapGetters([
      'getEnumCampaignGroupList',
      'getEnumCampaignMediaTypeList'
     
    ]),
    visible () {
      return true
    },
    dateRangeText: {
      get() {
        return this.dates.join(' ~ ');
      },
      set() {
        this.dates.join(' ~ ');
      },
    },
    validateCheckbox () {
      return [this.mediaTypeSelected.length > 0 || "対象反響媒体選択を選択してください"]
    }
  },
  created () {
    this.getAdvertisingPolicyGroup()
    this.getDataEnumCampaignMediaTypeList()
  },
  mounted () {
    this.statusAdvertSelected = this.policyCamCurrent.status
    this.nameAdvert = this.policyCamCurrent.name
    this.groupSelected = this.policyCamCurrent.groups
    this.dates = [this.policyCamCurrent.startDate, this.policyCamCurrent.endDate]
    this.implemenCost = this.policyCamCurrent.budget
    this.targetSelected = this.showOptionEdit({
      target20s: this.policyCamCurrent.target20s,
      target30s: this.policyCamCurrent.target30s,
      target40s: this.policyCamCurrent.target40s,
      target50s: this.policyCamCurrent.target50s,
      target60s: this.policyCamCurrent.target60s,
      target70s: this.policyCamCurrent.target70s,
      targetMale: this.policyCamCurrent.targetMale,
      targetFemale: this.policyCamCurrent.targetFemale
    })
    this.purposeSelected = this.showOptionEdit({
      purposeRecognition: this.policyCamCurrent.purposeRecognition,
      purposePersuade: this.policyCamCurrent.purposePersuade,
      purposePromote: this.policyCamCurrent.purposePromote
    })
    this.methodSelected = this.showOptionEdit({
      methodDm: this.policyCamCurrent.methodDm,
      methodEmail: this.policyCamCurrent.methodEmail,
      methodTv: this.policyCamCurrent.methodTv,
      methodSns: this.policyCamCurrent.methodSns,
      methodWeb: this.policyCamCurrent.methodWeb,
      methodBillboard: this.policyCamCurrent.methodBillboard
    })
    this.textDescription = this.policyCamCurrent.note
    this.mediaTypeSelected = this.policyCamCurrent.mediaTypes.map((item) => item.id)
    // this.placeholderFile1 = this.policyCamCurrent.attachment1Url ? showTextLength(this.policyCamCurrent.attachment1Url, 50, 20) : ''
    if(this.policyCamCurrent.attachment1Url) {
      this.placeholderFile1 = this.$options.filters.filename(this.policyCamCurrent.attachment1Url)
      this.fileAttach1 = new File([this.placeholderFile1], this.placeholderFile1, {
                  type: "application/pdf",
                })
    }
    if(this.policyCamCurrent.attachment2Url) {
      this.placeholderFile2 = this.$options.filters.filename(this.policyCamCurrent.attachment2Url);
      this.fileAttach2 = new File([this.placeholderFile2], this.placeholderFile2, {
                  type: "application/pdf",
                })
    }
    if(this.policyCamCurrent.attachment3Url) {
      this.placeholderFile3 = this.$options.filters.filename(this.policyCamCurrent.attachment3Url);
      this.fileAttach3 = new File([this.placeholderFile3], this.placeholderFile3, {
                  type: "application/pdf",
                })
    }
  },
  methods: {
    handlErrorView,
    getCopyErrorTextView,
    showTextLength,
    ...mapMutations([
      'setCopyErrorText',
      'setAlertSuccess',
      'setAlertError',
      'setLoadingOverlayShow',
      'setLoadingOverlayHide',
      'setEnumCampaignGroupList',
      'setCampaignMediaTypeList',
      'setPermissionUser',
      'setRoleAdminUser'
    ]),

    formatDate (event) {
      if (new Date(event[0]).getTime() - new Date(event[1]).getTime() > 0) {
        this.dates = [event[1],event[0]]
      }
    },

    closeDialog() {
      this.$emit('close');
      this.$refs.formCreateAdvert.resetValidation()
      this.$refs.formCreateAdvert.reset();
      this.dates = [];
      this.implemenCost = 0
    },
    handleFileUpload(obj, name = null) {
      if (obj) {
        if (name === 'file1') {
          this.fileAttach1UploadStatus = true
          this.previewFile1 = URL.createObjectURL(obj)
        }
        if (name === 'file2') {
          this.fileAttach2UploadStatus = true
          this.previewFile2 = URL.createObjectURL(obj)
        }
        if (name === 'file3') {
          this.fileAttach3UploadStatus = true
          this.previewFile3 = URL.createObjectURL(obj)
        }
      } else {
        if (name === 'file1') {
          this.fileAttach1UploadStatus = false
          this.previewFile1 = null
          this.placeholderFile1 = null
        }
        if (name === 'file2') {
          this.fileAttach2UploadStatus = false
          this.previewFile2 = null
          this.placeholderFile2 = null
        }
        if (name === 'file3') {
          this.fileAttach3UploadStatus = false
          this.previewFile3 = null
          this.placeholderFile3 = null
        }
      }
    },
    isMediaType (index) {
      return this.getEnumCampaignMediaTypeList.length -1 === index
    },
    async submitForm() {
      this.$refs.formCreateAdvert.validate();
      if (this.$refs.formCreateAdvert.validate()) {
        // process data create
        let sttUploadFile = true
        const dataCreate = {
          campaignId: this.idPolicy,
          status: this.statusAdvertSelected,
          name: this.nameAdvert,
          groups: this.groupSelected,
          startDate: this.dates[0],
          endDate: this.dates[1],
          budget: parseInt(this.implemenCost),
          note: this.textDescription,
          target20s: false,
          target30s: false,
          target40s: false,
          target50s: false,
          target60s: false,
          target70s: false,
          targetFemale: false,
          targetMale: false,
          purposeRecognition: false,
          purposePromote: false,
          purposePersuade: false,
          methodDm: false,
          methodEmail: false,
          methodBillboard: false,
          methodTv: false,
          methodSns: false,
          methodWeb: false,
          mediaTypeIds: this.mediaTypeSelected
        }
        if (this.fileAttach1) {
          if (this.fileAttach1UploadStatus) {
            const fileURl1 =  await this.uploadURL(this.fileAttach1)
            if (fileURl1) {
              await this.actionPutPdf(this.fileAttach1, fileURl1.uploadUrl)
              dataCreate.attachment1 = fileURl1.fileUrl
            } else {
              sttUploadFile = false
            } 
          } else {
            delete dataCreate.attachment1
          }
        } else if (!this.placeholderFile1) {
          dataCreate.attachment1 = null
        }
        if (this.fileAttach2) {
          if (this.fileAttach2UploadStatus) {
            const fileURl2 =  await this.uploadURL(this.fileAttach2)
            if (fileURl2) {
              await this.actionPutPdf(this.fileAttach2, fileURl2.uploadUrl)
              dataCreate.attachment2 = fileURl2.fileUrl
            } else {
              sttUploadFile = false
            } 
          } else {
            delete dataCreate.attachment2
          }
        }else if (!this.placeholderFile2) {
          dataCreate.attachment2 = null
        }
        if (this.fileAttach3) {
          if (this.fileAttach3UploadStatus) {
            const fileURl3 =  await this.uploadURL(this.fileAttach3)
            if (fileURl3) {
              await this.actionPutPdf(this.fileAttach3, fileURl3.uploadUrl)
              dataCreate.attachment3 = fileURl3.fileUrl
            } else {
              sttUploadFile = false
            } 
          } else {
            delete dataCreate.attachment3
          }
        }else if (!this.placeholderFile3) {
          dataCreate.attachment3 = null
        }
        if (this.targetSelected.length > 0) {
          this.targetSelected.map((ite) => {
            dataCreate[ite] = true
            return ite
          })
        }
        if (this.purposeSelected.length > 0) {
          this.purposeSelected.map((ite) => {
            dataCreate[ite] = true
            return ite
          })
        }
        if (this.methodSelected.length > 0) {
          this.methodSelected.map((ite) => {
            dataCreate[ite] = true
            return ite
          })
        }
        if (sttUploadFile) {
          this.setLoadingOverlayShow()
          const variables = {
              ...dataCreate
            }
          await this.$apollo.mutate({
            mutation: gql`${EDIT_CAMPAIGN_ADV_POLICIES}`,
            variables: variables,
            fetchPolicy: 'no-cache'
          }).then((data) => {
            this.setLoadingOverlayHide()
            if (data.data.updateCampaign) {
              this.resetForm()
              this.$emit('fetchList');
              this.$emit('reload')
              this.setAlertSuccess('作成しました。')
            }
          }).catch(async (error) => {
            this.setLoadingOverlayHide()
            this.setCopyErrorText(this.getCopyErrorTextView(EDIT_CAMPAIGN_ADV_POLICIES, variables, error.graphQLErrors))
            const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
            if (errorTmp) {
              this.setAlertError(errorTmp, { root: true })
            }
          })
        }
      } else {
        this.isValid = false
      }
    },
    async uploadURL(file) {
      // if (v && v.size < 10485760) {
      const variables = {
              contentType: file.type,
              contentLength: file.size,
              fileName: file.name
            }
      try  {
        this.setLoadingOverlayShow()
        const fileURl = await this.$apollo
          .mutate({
            mutation: gql`${GENERATE_UPLOAD_URL}`,
            variables: variables,
          })
          if (fileURl.data.generateUploadUrl) {
            this.setLoadingOverlayHide()
            return fileURl.data.generateUploadUrl
          }
          return ''
      } catch (error) {
        this.setLoadingOverlayHide()
        this.setCopyErrorText(this.getCopyErrorTextView(GENERATE_UPLOAD_URL, variables, error.graphQLErrors))
        const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true })
        }
        return ''
      }
    },

      async actionPutPdf (file, url) {
        this.setLoadingOverlayShow()
        await axios.put(
            url,
            file,
            {
              headers: {
                'Content-Type': file.type,
              },
            },
          )
        this.setLoadingOverlayHide()
    },

    resetForm() {
      this.$refs.formCreateAdvert.resetValidation()
      this.$refs.formCreateAdvert.reset();
      this.dates = [];
      this.implemenCost = 0
      this.$emit('close');
    },
    async getAdvertisingPolicyGroup () {
      await this.$apollo.query({
        query: gql`${ENUM_CAMPAIGN_GROUP_LIST}`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        if (data.data.enumCampaignGroupList.length > 0) {
          const dataEnumCampaignGroupList = data.data.enumCampaignGroupList.map((item) => item.name)
          this.setEnumCampaignGroupList({ enumCampaignGroupList: dataEnumCampaignGroupList })
        }
      }).catch(async (error) => {
        this.setCopyErrorText(this.getCopyErrorTextView(ENUM_CAMPAIGN_GROUP_LIST, null, error.graphQLErrors))
        const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true })
        }
      })
    },
    async getDataEnumCampaignMediaTypeList () {
      await this.$apollo.query({
        query: gql`${ENUM_CAMPAIGN_MEDIA_TYPE_LIST}`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        if (data.data.enumCampaignMediaTypeList.length > 0) {
          // #41068
          const dataFilter = data.data.enumCampaignMediaTypeList.filter((item) => item.id !== 5 && item.id !== 6 && item.id !== 8 && item.id !== 12 && item.id !== 14)
          this.setCampaignMediaTypeList({ enumCampaignMediaTypeList: dataFilter })
        }
      }).catch(async (error) => {
        this.setCopyErrorText(this.getCopyErrorTextView(ENUM_CAMPAIGN_MEDIA_TYPE_LIST, null, error.graphQLErrors))
        const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true })
        }
      })
    },
    previewFileAtt (ind) {
      const preview = [this.previewFile1, this.previewFile2, this.previewFile3]
      if (preview[ind - 1]) return preview[ind - 1];

      const urls = [this.policyCamCurrent.attachment1Url, this.policyCamCurrent.attachment2Url, this.policyCamCurrent.attachment3Url];
      if (urls[ind - 1]) return urls[ind - 1];
      
      return false
    },

    // show edit
    showOptionEdit (option = null) {
      const arrOption = []
      if (option) {
        Object.keys(option).forEach(function (key) {
          if (option[key]) {
            arrOption.push(key)
          }
        });
        return arrOption
      }
      return arrOption
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input--selection-controls .v-input__slot > .v-label {
    color: #000000 !important;
  }
  .v-select.v-input--dense .v-select__selection--comma {
    color: #000000 !important;
  }
  .theme--light.v-input input, .theme--light.v-input textarea {
    color: #000000 !important;
  }
  .v-dialog {
    background-color: var(--bg_color_f8f8f8);
    max-width: 1100px;
  }
  .v-input:not(.w-100):not(.v-input--checkbox):not(.v-textarea):not(.v-file-input),
  .currency-input input {
    width: 220px;
    max-width: 220px;
  }
  .date-text input {
    font-size: 14px !important;
  }
  .v-input--checkbox label {
    white-space: nowrap;
  }
  .v-file-input {
    max-width: 415px;
  }
  .v-chip {
    color: var(--text_white);
  }
  .--child {
    height: 70vh
  }
}
label {
  font-size: 10px;
}
.previewFile {
  cursor: pointer;
}
</style>