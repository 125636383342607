<template>
  <document-request-tab>
    <template v-slot:title class="pl-5 booking-list-title"
      >{{ $route.name }}
    </template>
    <template v-slot:content>
      <v-card>
        <Table
          :filter="filter"
          ref="table"
          :itemsPerPage="10"
          :itemsPerPageOptions="[10, 20, 50, 100, 200, 500]"
          :attr="{
            dense: true,
            'no-data-text': $t('rules.noData'),
            'item-key': 'id',
          }"
          :headers="headers"
          :total="total"
          :funReset="getWebRequestlistDocumentRequestlists"
          :items="webRequestlistDocumentRequestlistFalse"
          :multiSort="true"
          :sortField="[]"
        >
          <template v-slot:[`item.mediasType`]="{ item }">
            <span>{{
              item.medias && item.medias.map(c => c.mediaType).join(', ')
            }}</span>
          </template>
          <template v-slot:[`item.picName`]="{ item }">
            <div v-if="item.campaign">
              {{ item.campaign.name }}
            </div>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt.substr(5, 2) }}/{{ item.createdAt.substr(8, 2) }}
          </template>
          <template v-slot:[`item.isCompany`]="{ item }">
            <div v-if="item.client">
              {{ item.client.isCompany ? '法人' : '個人' }}
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div v-if="item.client">
              {{ item.client.name }}
            </div>
          </template>
          <template v-slot:[`item.forVmAndforVs`]="{ item }">
            <span v-if="item.forVm">VM</span>
            <span v-if="item.forVs">,&nbsp;VS</span>
          </template>
          <template v-slot:[`item.importMethod`]="{ item }">
            {{ item.importMethod.toUpperCase() }}
          </template>
          <template v-slot:[`item.user`]="{ item }">
            <template v-if="item.client">
              <v-btn
                class="t-btn--prm"
                icon
                target="_blank"
                :to="
                  '/customer-details/' +
                    item.client.id +
                    '?typeClient=' +
                    item.client.type
                "
                @click="idDoc = item.id"
              >
                <v-icon>mdi-account</v-icon>
              </v-btn>
            </template>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <v-btn
              :disabled="!checkPerUser"
              class="btn-crm-primary"
              style="border: 0px"
              @click="handleClickEdit(item)"
              icon
            >
              <v-icon x-large>mdi-pencil-circle</v-icon>
            </v-btn>
          </template>
        </Table>
      </v-card>
      <!-- Dialog -->
      <documentRequestDetailCorporation
        v-if="checkPerUser"
        :dataUrl="data"
        :visible="visible === 0"
        @close="visible = -1"
        @reloadList="reloadList"
      ></documentRequestDetailCorporation>
      <documentRequestDetailIndividual
        v-if="checkPerUser"
        :dataUrl="data"
        :visible="visible === 1"
        @close="visible = -1"
        @reloadList="reloadList"
      ></documentRequestDetailIndividual>
      <SalesRepresentativeAllocation
        v-if="checkPerUser"
        :visible="visible === 2"
        @close="visible = -1"
      />
    </template>
  </document-request-tab>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import documentRequestTab from '../documentRequestTab.vue';
import documentRequestDetailIndividual from '../DuplicateExistingCustomers/documentRequestDetailIndividual.vue';
import documentRequestDetailCorporation from '../DuplicateExistingCustomers/documentRequestDetailCorporation.vue';
import SalesRepresentativeAllocation from '../DuplicateExistingCustomers/SalesRepresentativeAllocation';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import { WEB_REQUESTLIST_DOCUMENT_REQUESTLIST } from '@/api/graphql/dashboard/webPageManage/documentrequest/DuplicateExistingCustomers/index.js';
import gql from 'graphql-tag';
import Table from '@/components/Table/index.vue';

export default {
  name: 'documentAll',
  components: {
    documentRequestTab,
    documentRequestDetailIndividual,
    documentRequestDetailCorporation,
    SalesRepresentativeAllocation,
    Table,
  },
  data() {
    return {
      filter: {
        hasPic: null,
        forVm: true,
      },
      total: null,
      webRequestlistDocumentRequestlistFalse: null,
      data: {},
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      selected: [],
      idDoc: '',
      visible: -1,
      // Table
      headers: [
        {
          text: '受信日',
          value: 'createdAt',
          fieldName: 'webRequest.createdAt'
        },
        {
          text: '反響媒体',
          fieldName: 'media.mediaType',
          value: 'mediasType',
        },
        {
          text: this.$t('webPageManage.documentRequest.CustomerDetails'),
          sortable: false,
          value: 'user',
          algin: 'center',
        },
        {
          text: '法人/個人',
          fieldName: 'client.isCompany',
          value: 'isCompany',
        },
        {
          text: '名前/会社名',
          sortable: false,
          value: 'name',
        },
        {
          text: '請求資料',
          fieldName: 'webRequest.forVs',
          value: 'forVmAndforVs',
        },
        {
          text: '広告施策',
          fieldName: 'campaign.name',
          value: 'picName',
        },
        {
          text: '取り込み方法',
          fieldName: 'webRequest.importMethod',
          value: 'importMethod',
        },
        {
          text: this.$t('webPageManage.documentRequest.detail'),
          sortable: false,
          value: 'edit',
          algin: 'center',
        },
      ],
    };
  },

  mounted() {
    if (this.$refs.table) {
      this.$refs.table.reset();
    }
  },
  methods: {
    ...mapActions([
      'getWebRequestlistDocumentRequestlist',
      'getWebrequestNotIscopanyDocumentRequestList',
    ]),
    ...mapMutations(['setWebRequestlistDocumentRequestListNull']),

    async getWebRequestlistDocumentRequestlists(variables) {
      if (variables) {
        await this.$apollo
          .query({
            query: gql`
              ${WEB_REQUESTLIST_DOCUMENT_REQUESTLIST}
            `,
            variables: variables,
            fetchPolicy: 'no-cache',
          })
          .then(data => {
            this.webRequestlistDocumentRequestlistFalse =
              data.data.webRequestList.items;
            this.total = data.data.webRequestList.total;
          })
          .catch(error => {
            console.error(error);
          });
      }
    },

    reloadList(value, id) {
      const newArr = this.webRequestlistDocumentRequestlistNull;
      const objIndex = newArr.findIndex(e => e.id === id);
      newArr[objIndex].client.pic.id = value.client.pic.id;
      newArr[objIndex].client.pic.name = value.client.pic.name;
      this.setWebRequestlistDocumentRequestListNull(newArr);
    },

    async handleClickEdit(item) {
      await this.getWebrequestNotIscopanyDocumentRequestList(item.id).then(
        async result => {
          this.data = await {
            id: item.id,
            idClient: item.client.id,
            typeClient: item.client.type,
          };
          if (item.client.isCompany === true) {
            this.visible = 0;
          } else {
            this.visible = 1;
          }
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table-header {
    tr {
      th {
        font-size: 14px !important;
        color: #757575 !important;
        font-weight: bold !important;
      }
    }
  }
  tbody {
    tr {
      height: auto !important;
      td {
        font-size: 14px !important;
        color: #757575 !important;
        font-weight: 400 !important;
        .v-btn--icon.v-size--default {
          height: 32px;
          width: 32px;
          margin: 2px;
        }
      }
    }
  }
  .v-data-footer__select {
    font-size: 13px !important;
    color: #000000;
    font-weight: 500;
    .v-select__selection--comma {
      font-size: 13px !important;
      color: #000000;
      font-weight: 500;
    }
  }
  .v-data-table-header__icon {
    color: #000 !important;
  }
  .v-data-footer__pagination {
    display: none !important;
  }
  .v-input__append-inner {
    .v-icon__svg {
      color: #333333 !important;
    }
  }
}
// .v-data-table::v-deep {
//   th {
//     color: #000 !important;
//   }
//   .v-data-footer,
//   .v-select__selection {
//     font-size: 14px !important;
//   }
// }
.message {
  margin-left: 5px;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  text-align: center;
  padding-top: 6px;
  background-color: red;
  color: white;
}
.textCustomerSalesRepresentative {
  font-weight: bold;
  color: red;
}
</style>
